import React from 'react';
import { StyledTitleModuleCardSkeleton } from './styled';

const TitleModuleCardSkeleton = () => {
  return (
    <StyledTitleModuleCardSkeleton>
      <StyledTitleModuleCardSkeleton.Caption 
        style={{ 
          width: '48px', 
          height: '12px'
        }} 
      />
    </StyledTitleModuleCardSkeleton>
  );
};

export default TitleModuleCardSkeleton;