import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';

export const StyledQuestionSkeleton = styled.div`
`;

StyledQuestionSkeleton.Progress = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

StyledQuestionSkeleton.Item = styled.div`
  height: 20px;
  border-radius: 5px;
  margin-bottom: 10px;

  ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return `
      background: ${color};
    `;
  }}

  ${({ width }) => {
    if (width) {
      return `width: ${width};`;
    }
  }}

  ${({ height }) => {
    if (height) {
      return `height: ${height};`;
    }
  }}
`;