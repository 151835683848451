import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';
import { StyledNews } from '../../../templates/News/styled';
import { StyledBroadcastCard } from '../../../molecules/BroadcastCard/styled';
import { StyledHeadingSectionSkeleton } from '../HeadingSectionSkeleton/styled';

export const StyledNewsSkeleton = styled(StyledNews)``;

StyledNewsSkeleton.Card = styled(StyledBroadcastCard)`
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);
    return color;
  }};
`;

StyledNewsSkeleton.Item = styled(StyledHeadingSectionSkeleton.Item)`
  border-radius: 3px;
`;