import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';

export const StyledTitleModuleCardSkeleton = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 16px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${({ theme }) => {
    const color = getColor(theme.scheme.content.border, theme.palette);
    return `1px solid ${color};`;
  }};
`;

StyledTitleModuleCardSkeleton.Caption = styled.div`
  border-radius: 3px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};
`;