import React, { lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import { BREAKPOINT } from '../../../constants/layout';
import useViewport from '@hooks/useViewport';
import SkeletonReadingPlatform from '../../organisms/Skeletons/ReadingPlatform';

const Desktop = lazy(() => import('./Desktop'));

const ReadingPlatform = ({ isGranted }) => {
  const { moduleId, chapterId } = useParams();
  const location = useLocation();
  const { width } = useViewport();
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const lessonId = Number(queryParams.get('lessonId'));
  const isShared = Boolean(queryParams.get('shared'));
  const sharedParam = isShared ? '?shared=1' : '';
  const lessonPath = lessonId ? `/lessons/${lessonId}` : '';
  const [load, setLoad]  = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, 300);
  }, []);

  // Redirect to reading platform for mobile
  if (width < BREAKPOINT) {
    return (
      <Redirect
        to={`/modules/${moduleId}/chapters/${chapterId}${lessonPath}${sharedParam}`}
      />
    );
  }

  if (!load) {
    return null;
  }

  return (
    <Suspense fallback={<SkeletonReadingPlatform />}>
      <Desktop isGranted={isGranted} />
    </Suspense>
  );
};

ReadingPlatform.propTypes = {
  isGranted: PropTypes.bool.isRequired,
};

export default ReadingPlatform;
