import React, { useContext, useState } from 'react';
import Popup from 'reactjs-popup';
import { Close } from '@styled-icons/zondicons';
import PropTypes from 'prop-types';
import { copyTextToClipboard } from '../../../utils/general';
import {
  TelegramShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from 'react-share';
import { StyledShare } from './styled';
import Image from '../../atoms/Image';
import Button from '../../atoms/Button';
import useViewport from '@hooks/useViewport';
import { getColor } from '../../../utils/theme';
import { ThemeContext } from 'styled-components';
import { BREAKPOINT } from '../../../constants/layout';
import {
  onAcademyModuleShared,
  onAcademyUnboxingShareClicked,
} from '../../../utils/clevertapAnalytics';

const Share = ({ trigger, shareLink, source, volume, title }) => {
  const { width } = useViewport();
  const [wording, setWording] = useState('Copy Link');
  const theme = useContext(ThemeContext);

  const desktopProps = {
    position: 'center center',
    overlayStyle: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
    contentStyle: {
      maxWidth: 400,
      width: 400,
      background: getColor(theme.scheme.popup.content, theme.palette),
      borderColor: getColor(theme.scheme.popup.content, theme.palette),
      borderRadius: 8,
      padding: 0,
    },
  };

  const mobileProps = {
    overlayStyle: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
    contentStyle: {
      position: 'absolute',
      background: getColor(theme.scheme.popup.content, theme.palette),
      borderColor: getColor(theme.scheme.popup.content, theme.palette),
      bottom: 0,
      width: '100%',
      borderRadius: '20px 20px 0px 0px',
    },
    position: 'center center',
  };

  const usedProps = width >= BREAKPOINT ? desktopProps : mobileProps;

  const { Separator, Wrapper, CTAWrapper, Title, Item } = StyledShare;

  const sharedURL = `${process.env.REACT_APP_STOCKBIT_HOST_URL}${shareLink}`;

  return (
    <Popup
      trigger={trigger}
      {...usedProps}
      closeOnDocumentClick
      lockScroll
      nested
      modal
    >
      {(close) => {
        return (
          <StyledShare>
            <Close
              size={15}
              onClick={() => {
                close();
              }}
              color="#B5B5B5"
            />
            <div>
              <Title>Share</Title>
              <Separator>
                <Wrapper>
                  <WhatsappShareButton
                    url={sharedURL}
                    onClick={() => {
                      if (source === 'academy') {
                        onAcademyModuleShared();
                      } else {
                        onAcademyUnboxingShareClicked(volume, 'WhatsApp', title);
                      }
                    }}
                  >
                    <Item>
                      <Image
                        src={`${process.env.PUBLIC_URL}/img/whatsapp.svg`}
                        alt="whatsapp"
                        placeholder={`${process.env.PUBLIC_URL}/img/indicator.gif`}
                      />
                      <span className="desc">Whatsapp</span>
                    </Item>
                  </WhatsappShareButton>
                  <TelegramShareButton
                    url={sharedURL}
                    onClick={() => {
                      if (source === 'academy') {
                        onAcademyModuleShared();
                      } else {
                        onAcademyUnboxingShareClicked(volume, 'Telegram', title);
                      }
                    }}
                  >
                    <Item>
                      <Image
                        src={`${process.env.PUBLIC_URL}/img/telegram.svg`}
                        alt="telegram"
                        placeholder={`${process.env.PUBLIC_URL}/img/indicator.gif`}
                      />
                      <span className="desc">Telegram</span>
                    </Item>
                  </TelegramShareButton>
                  <FacebookShareButton
                    url={sharedURL}
                    onClick={() => {
                      if (source === 'academy') {
                        onAcademyModuleShared();
                      } else {
                        onAcademyUnboxingShareClicked(volume, 'Facebook', title);
                      }
                    }}
                  >
                    <Item>
                      <Image
                        src={`${process.env.PUBLIC_URL}/img/facebook.svg`}
                        alt="facebook"
                        placeholder={`${process.env.PUBLIC_URL}/img/indicator.gif`}
                      />
                      <span className="desc">Facebook</span>
                    </Item>
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={sharedURL}
                    onClick={() => {
                      if (source === 'academy') {
                        onAcademyModuleShared();
                      } else {
                        onAcademyUnboxingShareClicked(volume, 'Twitter', title);
                      }
                    }}
                  >
                    <Item>
                      <Image
                        src={`${process.env.PUBLIC_URL}/img/twitter.svg`}
                        alt="twitter"
                        placeholder={`${process.env.PUBLIC_URL}/img/indicator.gif`}
                      />
                      <span className="desc">Twitter</span>
                    </Item>
                  </TwitterShareButton>
                </Wrapper>
              </Separator>
              <CTAWrapper>
                <Button
                  backgroundColor="#B5B5B5"
                  fontSize="16px"
                  fontWeight="bold"
                  type="copy"
                  ripple
                  onClick={() => {
                    copyTextToClipboard(sharedURL);

                    if (source === 'academy') {
                      onAcademyModuleShared();
                    } else {
                      onAcademyUnboxingShareClicked(volume, 'Copy Link', title);
                    }

                    setWording('Link Copied!');

                    setTimeout(() => {
                      close();
                      setWording('Copy Link');
                    }, 800);
                  }}
                >
                  <Image
                    src={`${process.env.PUBLIC_URL}/img/chain-copy.svg`}
                    alt="copy"
                  />
                  {wording}
                </Button>
              </CTAWrapper>
            </div>
          </StyledShare>
        );
      }}
    </Popup>
  );
};

Share.propTypes = {
  trigger: PropTypes.node.isRequired,
  shareLink: PropTypes.string,
  source: PropTypes.oneOf(['academy', 'unboxing']),
  volume: PropTypes.string,
  title: PropTypes.string,
};

Share.defaultProps = {
  shareLink: '/',
  source: 'academy',
  title: '',
};

export default Share;
