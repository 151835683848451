import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyledLoading } from '../components/molecules/Loading/styled';
import { removeFromLS } from '../utils/storage';
import Image from '../components/atoms/Image';
import LoadingRing from '../components/atoms/LoadingRing';
import { Redirect } from 'react-router-dom';
import { useStore } from 'jotai';
import { isGrantedAtom } from '../store';

const Error = ({ code }) => {
  const [iframeIsDone, setIframeIsDone] = useState(false);
  const store = useStore();

  removeFromLS('HAS_GONE_THROUGH_AUTH');

  useEffect(() => {
    if (code === '401') {
      store.set(isGrantedAtom, false);
      removeFromLS('auth');
    }

    if (code !== '401') {
      setIframeIsDone(true);
    }

    const timeout = setTimeout(() => {
      setIframeIsDone(true);
    }, 15000);

    // This code can only run if and only if we adjust CORS policy on https://stockbit.com and https://academy.stockbit.com.
    // parentApp.onload = () => {
    //   parentApp.close();
    // };

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const LoadingComponent =
    code === '401' ? (
      <Image
        src={`${process.env.PUBLIC_URL}/img/icon-hat.svg`}
        alt="logo"
        width={84}
      />
    ) : (
      <LoadingRing />
    );

  if (code === '401' && iframeIsDone) {
    return (
      <Redirect to='/' />
    );
  }

  return (
    <StyledLoading>
      {iframeIsDone ? (
        <>
          <Image
            src={`${process.env.PUBLIC_URL}/img/stockbitsquare.svg`}
            alt="logo"
            width={120}
          />
          <h1>{`Error ${code}`}</h1>
        </>
      ) : (
        <>{LoadingComponent}</>
      )}
    </StyledLoading>
  );
};

Error.propTypes = {
  code: PropTypes.string.isRequired,
};

export default Error;
