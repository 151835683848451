import React from 'react';
import PropTypes from 'prop-types';
import { StyledMainCardSkeleton } from './Desktop.styled';

const MainCardSkeleton = ({ order }) => {
  return (
    <StyledMainCardSkeleton order={order} to="/">
      <StyledMainCardSkeleton.ModuleCard>
        <StyledMainCardSkeleton.Image/>
        <StyledMainCardSkeleton.Caption 
          style={{ 
            width: '77px', 
            marginBottom: '16px' 
          }} 
        />
        <StyledMainCardSkeleton.Caption 
          style={{ 
            width: '229px', 
            marginBottom: '8px' 
          }} 
        />
        <StyledMainCardSkeleton.Caption 
          style={{ 
            width: '114px', 
          }} 
        />
      </StyledMainCardSkeleton.ModuleCard>
    </StyledMainCardSkeleton>
  );
};

MainCardSkeleton.propTypes = {
  order: PropTypes.number.isRequired,
};


export default MainCardSkeleton;