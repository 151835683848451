import styled from 'styled-components';
import { StyledLessonShowcase } from '../../../organisms/LessonShowcase/Mobile.styled';

export const StyledLessonShowcaseSkeleton = styled(StyledLessonShowcase)`
  padding: 0;
`;

StyledLessonShowcaseSkeleton.Content = styled(StyledLessonShowcase.Content)`
  padding: 20px;
`;
