import { useEffect, useState } from 'react';
import { getFromLS, setToLS } from '../utils/storage';
import light from '../themes/light.json';
import dark from '../themes/dark.json';
import scheme from '../themes/scheme.json';

interface ThemeSetter {
  dark: () => void;
  light: () => void;
}

const themeSetter: ThemeSetter = {
  dark: () => setToLS('dark', dark),
  light: () => setToLS('light', light),
};

const useTheme = (location: Location) => {
  const [palette, setPalette] = useState(light.palette);
  const [themeLoaded, setThemeLoaded] = useState(false);

  const getTheme = () => {
    const SCHEME_VERSION = getFromLS('SCHEME_VERSION') as string;
    const queryParams = new URLSearchParams(location.search);
    const availableThemes = Object.keys(themeSetter) as Array<keyof ThemeSetter>;
    const themeName = availableThemes.includes(queryParams.get('theme') as keyof ThemeSetter)
      ? queryParams.get('theme') as keyof ThemeSetter
      : 'light';
    const schemeVersionIsNotExisted = SCHEME_VERSION === undefined;
    const schemeVersionIsExisted = !schemeVersionIsNotExisted;
    const schemeVersionExistButDifferent =
      schemeVersionIsExisted &&
      scheme.version &&
      SCHEME_VERSION !== scheme.version;
    const CURRENT_SCHEME_VERSION =
      schemeVersionIsNotExisted || schemeVersionExistButDifferent
        ? scheme.version || 'v-00000'
        : 'v-00000';

    // Make sure that theme is updated with its current version
    if (schemeVersionIsNotExisted || schemeVersionExistButDifferent) {
      setToLS('SCHEME_VERSION', CURRENT_SCHEME_VERSION);
      (Object.keys(themeSetter) as Array<keyof ThemeSetter>).forEach((key) => {
        themeSetter[key]();
      });
    }

    let paletteFromLS: JSONValue = getFromLS(themeName as keyof ThemeSetter) as JSONObject;

    if (!paletteFromLS) {
      themeSetter[themeName]();
      paletteFromLS = getFromLS(themeName) as JSONObject;
    }

    return {
      palette: paletteFromLS?.palette as typeof light.palette,
    };
  };

  useEffect(() => {
    const { palette } = getTheme();
    setPalette(palette as typeof light.palette);
    setThemeLoaded(true);
  }, []);

  return {
    palette,
    scheme,
    themeLoaded,
  };
};

export default useTheme;