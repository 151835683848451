import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';
import { BREAKPOINT, MAX_WIDTH_DESKTOP } from '../../../../constants/layout';

import { StyledMainShowcase } from '../../../organisms/MainShowcase/Desktop.styled';

export const StyledTabCategory = styled.div`
  max-width: ${MAX_WIDTH_DESKTOP}px;
  min-width: ${BREAKPOINT}px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

StyledTabCategory.TabContainer = styled(StyledMainShowcase.TabContainer)`
  width: 100%;
  margin-bottom: 0;
`;

StyledTabCategory.Chip = styled.div`
  height: 28px;
  border-radius: 2px;

  ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return `
      background: ${color};
    `;
  }}

  ${({ width }) => {
    if (width) {
      return `width: ${width};`;
    }
  }}
`;
