import styled from 'styled-components';
import { getColor } from '../../../utils/theme';

export const StyledShare = styled.div`
  background: transparent;
  position: relative;
  width: 100%;
  padding-top: 20px;
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};

  & > svg {
    top: 20px;
    right: 30px;
    position: absolute;
    cursor: pointer;
  }

  & > .container {
    padding: 20px 30px;
  }
`;

StyledShare.Title = styled.span`
  font-family: ProximaNovaBd;
  font-weight: bold;
  font-size: 16px;
  margin-left: 30px;
  line-height: 21.6px;
  text-align: left;
`;

StyledShare.Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 648px;
  margin: 20px auto;
  padding: 24px;
`;

StyledShare.Separator = styled.div`
  border-bottom: 1px solid #ededed;
`;

StyledShare.CTAWrapper = styled.div`
  padding: 24px;
  max-width: 648px;
  margin: 0 auto;

  button {
    border: ${({ theme }) => {
    const color = getColor(theme.scheme.content.border, theme.palette);
    return `1px solid ${color}`;
  }};
  }
`;

StyledShare.Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .desc {
    margin-top: 10px;
    font-size: 13px;
    line-height: 17.55px;
  }
`;
