import React from 'react';
import { StyledLessonShowcaseSkeleton } from './Mobile.styled';
//import { StyledHeadingSectionSkeleton } from '../HeadingSectionSkeleton/styled';
import TitleModuleCardSkeleton from '../../../molecules/Skeletons/TitleModuleCardSkeleton';
import { Mobile as LessonItemSkeleton } from '../LessonItemSkeleton';

const LessonShowcaseSkeleton = () => {
  return (
    <StyledLessonShowcaseSkeleton data-testid="lesson-showcase-skeleton">
      <TitleModuleCardSkeleton/>
      <StyledLessonShowcaseSkeleton.Content>
        {[1, 2, 3, 4, 5, 6, 7].map((key) => {
          return <LessonItemSkeleton key={key} />;
        })}
      </StyledLessonShowcaseSkeleton.Content>
    </StyledLessonShowcaseSkeleton>
  );
};

export default LessonShowcaseSkeleton;
