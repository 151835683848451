import React from 'react';
import { Desktop as UnboxingCardSkeleton } from '../../../molecules/Skeletons/UnboxingCardSkeleton';
import UnboxingHeadlineSkeleton from '../../../molecules/Skeletons/UnboxingHeadlineSkeleton';
import { StyledUnboxingShowcase } from '../../UnboxingShowcase/Desktop.styled';
import { StyledUnboxingSkeleton } from './Mobile.styled';

const SkeletonUnboxingList = () => {
  const { Wrapper, UnboxingItems, TabContainer } = StyledUnboxingShowcase;
  const { Chip } = StyledUnboxingSkeleton;
  return (
    <StyledUnboxingShowcase data-testid={'unboxing-loading'}>
      <Wrapper>
        <UnboxingHeadlineSkeleton />
        <TabContainer>
          <Chip width="47px" />
          <Chip width="67px" />
          <Chip width="69px" />
        </TabContainer>

        <UnboxingItems>
          {[...Array(6).keys()].map((key) => {
            return (
              <UnboxingCardSkeleton key={key} />
            );
          })}
        </UnboxingItems>
      </Wrapper>
    </StyledUnboxingShowcase>
  );
};

export default SkeletonUnboxingList;
