import { useEffect } from 'react';

/* eslint-disable  @typescript-eslint/no-explicit-any */
type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[]; 
};

declare const window: WindowWithDataLayer;

const useAppendGtagOnIframe = () => {
  const isLoadedViaIframe = window.location !== window.parent.location;

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  function gtag(...args: any){
    window.dataLayer.push(...args);
  }

  useEffect(() => {
    if (isLoadedViaIframe) {
      const newScript = document.createElement('script');
      newScript.type = 'text/javascript';
      newScript.setAttribute('async', 'true');
      newScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?${process.env.REACT_APP_GTM_ID}`);
      document?.documentElement?.firstChild?.appendChild(newScript);
      window.dataLayer = window.dataLayer || [];
      gtag('js', new Date());
      gtag('config', process.env.REACT_APP_GTM_ID);
    }
  }, []);

  return isLoadedViaIframe;
};

export default useAppendGtagOnIframe;