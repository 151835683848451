import { getColor } from '../../../utils/theme';
import styled from 'styled-components';
import { StyledUnboxingSkeleton } from '../Skeletons/UnboxingList/Mobile.styled';

export const StyledModuleShowcase = styled.main`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.main.background, theme.palette);
    return color;
  }};
  padding-top: 44px;
  overflow-x: ${({ unscrollable }) => {
    if (unscrollable) {
      return 'hidden';
    }
    return 'auto';
  }
}};;
`;

StyledModuleShowcase.CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 70px 20px 60px 20px;

  margin-top: ${({ isSkeleton }) => {
    if (isSkeleton) {
      return '20px';
    }

    return '70px';
  }};

  &::nth-child(even) {
    margin-left: 0 !important;
  }
`;

StyledModuleShowcase.Title = styled.h2`
  font-size: 13px;
  height: 40px;
  line-height: 15;
  display: flex;
  align-items: center;
  padding: 3px 20px;
  font-weight: 700;
  margin: unset;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.main.background, theme.palette);
    return color;
  }};
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};
  ${({ theme }) => {
    const color = getColor(theme.scheme.main.border, theme.palette);
    return `border-bottom: 1px solid ${color};`;
  }}
`;

StyledModuleShowcase.ChipWrapper = styled(StyledUnboxingSkeleton.ChipWrapper)`
  height: 50px;
  display: flex;
  gap: 8px;
  padding: 11px 20px;
`;

StyledModuleShowcase.Chip = styled(StyledUnboxingSkeleton.Chip)``;
