import React from 'react';
import { StyledChapterShowcase } from '../../ChapterShowcase/Mobile.styled';
import { Mobile as MastHeadSkeleton } from '../../../molecules/Skeletons/MastHeadSkeleton';
import { Mobile as TitleMainCardSkeleton } from '../../../molecules/Skeletons/TitleMainCardSkeleton';
import { Mobile as ChapterShowcaseSkeleton } from '../../../molecules/Skeletons/ChapterShowcaseSkeleton';
import TitleChapterSkeleton from '../../../molecules/Skeletons/TitleChapterSkeleton';

const SkeletonChapterShowcase = () =>  {
  return (
    <StyledChapterShowcase data-testid="chapter-showcase-skeleton-mobile">
      <MastHeadSkeleton />
      <StyledChapterShowcase.Wrapper>
        <StyledChapterShowcase.Copy>
          <TitleChapterSkeleton/>
        </StyledChapterShowcase.Copy>
      </StyledChapterShowcase.Wrapper>
      <TitleMainCardSkeleton/>
      <StyledChapterShowcase.ListChapter>
        {[1, 2, 3].map((key) => {
          return <ChapterShowcaseSkeleton order={key} key={key} />;
        })}
      </StyledChapterShowcase.ListChapter>
    </StyledChapterShowcase>
  );
};

export default SkeletonChapterShowcase;
