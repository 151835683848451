import { StyledLoadingRing } from './styled';

const LoadingRing = () => (
  <StyledLoadingRing data-testid="loading-ring">
    <div />
    <div />
    <div />
  </StyledLoadingRing>
);

export default LoadingRing;
