import React from 'react';
import { StyledLeafletSkeleton } from './Desktop.styled';

const LeafletSkeleton = () => {
  return (
    <StyledLeafletSkeleton data-testid="leaflet-skeleton">
      <StyledLeafletSkeleton.Content>
        <StyledLeafletSkeleton.Video isDesktop />
        <StyledLeafletSkeleton.Title>
          <h3 className="title">
            <StyledLeafletSkeleton.Caption style={{ width: '183px' }}/>
          </h3>
          <span className="time">
            <StyledLeafletSkeleton.Caption style={{ width: '114px' }}/>
          </span>
        </StyledLeafletSkeleton.Title>
        <StyledLeafletSkeleton.Text>
          <StyledLeafletSkeleton.Caption style={{ width: '561px', marginBottom: '8px' }}/>
          <StyledLeafletSkeleton.Caption style={{ width: '561px', marginBottom: '8px' }}/>
          <StyledLeafletSkeleton.Caption style={{ width: '183px' }}/>
        </StyledLeafletSkeleton.Text>
        <StyledLeafletSkeleton.Pagination>
          <StyledLeafletSkeleton.Caption style={{ width: '114px' }}/>
          <StyledLeafletSkeleton.Caption style={{ width: '114px' }}/>
          <StyledLeafletSkeleton.Caption style={{ width: '114px' }}/>
        </StyledLeafletSkeleton.Pagination>
      </StyledLeafletSkeleton.Content>
    </StyledLeafletSkeleton>
  );
};

export default LeafletSkeleton;
