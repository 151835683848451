import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getColor } from '../../../utils/theme';

export const StyledLessonItem = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

StyledLessonItem.MainSection = styled.section`
  display: flex;
  width: calc(100% - 20px);
  flex: 0 0 calc(100% - 20px);
`;

StyledLessonItem.StatusSection = styled.section`
  display: flex;
  flex: 1 0 20px;
  align-items: center;
  width: 20px;

  svg {
    fill: ${({ theme }) => {
    const color = getColor(
      theme.scheme.lesson.label.progress.background,
      theme.palette,
    );
    return color;
  }};
  }
`;

StyledLessonItem.Heading = styled.article`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: calc(100% - 60px);
  justify-content: center;

  .title {
    color: ${({ theme, type }) => {
    const color = getColor(
      theme.scheme.lesson.heading.title[type],
      theme.palette,
    );
    return color;
  }};

    font-family: ${({ type }) => {
    if (type === 'progress' || type === 'unlocked') {
      return 'ProximaNovaBd';
    }

    return 'ProximaNovaRg';
  }};

    font-size: 16px;
    line-height: 22px;
    white-space: wrap;
    overflow: hidden;
    width: calc(100% - 16px);

    * {
      font-family: inherit;
    }
  }

  .time {
    color: ${({ theme, type }) => {
    const color = getColor(
      theme.scheme.lesson.heading.time[type],
      theme.palette,
    );
    return color;
  }};
    font-size: 12px;
    line-height: 16px;
  }
`;

StyledLessonItem.Label = styled.article`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  font-family: ProximaNovaBd;
  color: ${({ theme, type }) => {
    const color = getColor(theme.scheme.lesson.label[type].text, theme.palette);
    return color;
  }};
  background: ${({ theme, type }) => {
    const color = getColor(
      theme.scheme.lesson.label[type].background,
      theme.palette,
    );
    return color;
  }};

  ${({ theme, type }) => {
    const color = getColor(
      theme.scheme.lesson.label[type].border,
      theme.palette,
    );

    return `border: 2px solid ${color};`;
  }}

  border-radius: 4px;
`;
