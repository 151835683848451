import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';

export const StyledTitleChapterSkeleton = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

StyledTitleChapterSkeleton.Caption = styled.div`
  border-radius: 3px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};
`;