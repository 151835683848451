/**
 * Get color from theme.
 * @param {string} colorAlias - Color naming based on Greek alphabet + Hebrew (alpha, omega, bet, etc)
 * @param {Object} palette - Palette to pick the actual colors from
 * @returns {string} - Hex / RGBA / Gradient
 */
export const getColor = (colorAlias, palette) => {
  if (!palette) {
    return '#fff';
  }

  return palette[colorAlias];
};

/**
 * Get order
 * @param {string} order - Module order (1 - Infinity)
 * @returns {string} - Adjusted order (1 - 8)
 */
export const adjustOrder = (order) => {
  const MAX_ORDER = 8;

  if (order % MAX_ORDER === 0) {
    return MAX_ORDER;
  }

  return order % 8;
};

export const getUnboxingCategoryColor = (category) => {
  if (!category) {
    return 'primary';
  }
  switch (category.toLowerCase()) {
  case 'emiten':
    return 'secondary';
  case 'sector':
  default:
    return 'primary';
  }
};
