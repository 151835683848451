import React, { useEffect } from 'react';
import { StyledLoading } from '../components/molecules/Loading/styled';
import Image from '../components/atoms/Image';

const Refresh = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_STOCKBIT_BASE_URL}/academy`;
    }, 2000);
  }, []);

  return (
    <StyledLoading>
      <Image
        src={`${process.env.PUBLIC_URL}/img/icon-hat.svg`}
        alt="logo"
        width={84}
      />
    </StyledLoading>
  );
};

export default Refresh;
