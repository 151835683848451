import { AUTH_CONSTANT, Auth } from '../constants/auth';
import { getFromLS } from './storage';

export function isAuth(raw: unknown): raw is Auth {
  return raw instanceof Object && 'academy_token' in raw && 'timestamp' in raw;
}

/**
 * Make sure user is authenticated or not.
 * @returns {bool}
 */
export const getIsGranted = () => {
  const auth = getFromLS('auth');
  return auth && isAuth(auth) && auth[AUTH_CONSTANT.ACADEMY_TOKEN];
};

/**
 * Get token's timestamp
 */
export const getTimestamp = () => {
  const auth = getFromLS('auth');
  return auth && isAuth(auth) ? auth[AUTH_CONSTANT.TIMESTAMP] : -1;
};


/**
 * Expiration
 */
export const getIsExpired = (unixTimestamp: number) => {
  if (unixTimestamp === -1) {
    return false;
  }

  const miliseconds = unixTimestamp * 1000;
  const twentyThreeHoursInMiliseconds = 60 * 23 * 60 * 1000;

  const expirationDate = new Date(miliseconds + twentyThreeHoursInMiliseconds);
  const now = new Date();


  return now.getTime() > expirationDate.getTime();
};
