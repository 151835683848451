import styled from 'styled-components';
import { getColor } from '../../../utils/theme';
import { StyledMainShowcase } from '../../organisms/MainShowcase/Desktop.styled';

export const StyledUnboxingDetails = styled(StyledMainShowcase)``;

StyledUnboxingDetails.Wrapper = styled(StyledMainShowcase.Wrapper)`
  display: flex;
  max-width: 1040px;
`;

StyledUnboxingDetails.Heading = styled.section`
  margin: 21px auto;
  max-width: 1040px;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.subtext, theme.palette);
    return color;
  }};

  a {
    text-decoration: none;
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.subtext, theme.palette);
    return color;
  }};
  }

  a:hover {
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};
  }
`;

StyledUnboxingDetails.Left = styled.aside`
  width: 26.92318%;
  flex: 0 0 26.92318%;
  padding-right: 40px;

  .unboxing-btn-download, .unboxing-btn-share {
    font-family: ProximaNovaBd !important;
  }
`;

StyledUnboxingDetails.Right = styled.section`
  width: 73.07692%;
  flex: 0 0 73.07692%;
  height: 700px;
  border: ${({ theme }) => {
    const borderColor = getColor(theme.scheme.unboxing.card.border, theme.palette);
    return `1px solid ${borderColor}`;
  }};
  border-radius: 6px;
  overflow: hidden;
  position: relative;

  .a-loader,
  .m-unboxingError {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    position: absolute;
  }
`;

StyledUnboxingDetails.StyledPopupContent = styled.div`
background: transparent;
position: relative;

& > svg {
  top: 20px;
  right: 20px;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}
`;