export const CAROUSEL_PROPS = {
  additionalTransfrom: 0,
  autoPlaySpeed: 3000,
  centerMode: false,
  className: '',
  containerClass: 'container',
  dotListClass: '',
  arrows: false,
  draggable: true,
  focusOnSelect: false,
  itemClass: '',
  keyBoardControl: true,
  minimumTouchDrag: 80,
  partialVisible: true,
  renderButtonGroupOutside: false,
  renderDotsOutside: true,
  responsive: {
    mobile: {
      breakpoint: {
        max: 321,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 40,
    },
    mobile2: {
      breakpoint: {
        max: 360,
        min: 320,
      },
      items: 1,
      partialVisibilityGutter: 50,
    },
    mobile3: {
      breakpoint: {
        max: 400,
        min: 360,
      },
      items: 1,
      partialVisibilityGutter: 60,
    },
    mobile4: {
      breakpoint: {
        max: 890,
        min: 400,
      },
      items: 1,
      partialVisibilityGutter: 70,
    },
    mobile5: {
      breakpoint: {
        max: 1024,
        min: 728,
      },
      items: 2,
      partialVisibilityGutter: 80,
    },
  },
  showDots: true,
  sliderClass: '',
  slidesToSlide: 1,
  swipeable: true,
};

export type CAROUSEL_PROPS = typeof CAROUSEL_PROPS;

export const BANNER_CAROUSEL_PROPS = {
  additionalTransfrom: 0,
  autoPlaySpeed: 3000,
  centerMode: false,
  className: '',
  containerClass: 'container',
  dotListClass: '',
  arrows: false,
  draggable: true,
  focusOnSelect: false,
  itemClass: '',
  keyBoardControl: true,
  minimumTouchDrag: 80,
  partialVisible: true,
  renderButtonGroupOutside: false,
  renderDotsOutside: true,
  responsive: {
    mobile: {
      breakpoint: {
        max: 321,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 40,
    },
    mobile2: {
      breakpoint: {
        max: 360,
        min: 320,
      },
      items: 1,
      partialVisibilityGutter: 50,
    },
    mobile3: {
      breakpoint: {
        max: 400,
        min: 360,
      },
      items: 1,
      partialVisibilityGutter: 60,
    },
    mobile4: {
      breakpoint: {
        max: 890,
        min: 400,
      },
      items: 1,
      partialVisibilityGutter: 70,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 728,
      },
      items: 1,
      partialVisibilityGutter: 80,
    },
  },
  showDots: true,
  sliderClass: '',
  slidesToSlide: 1,
  swipeable: true,
};

export type BANNER_CAROUSEL_PROPS = typeof BANNER_CAROUSEL_PROPS;

export const UNBOXING_CAROUSEL_PROPS = {
  additionalTransfrom: 0,
  autoPlaySpeed: 3000,
  centerMode: false,
  className: '',
  containerClass: 'unboxing-slider-container',
  dotListClass: '',
  arrows: false,
  draggable: true,
  focusOnSelect: false,
  ssr: true,
  itemClass: 'unboxing-card-item',
  keyBoardControl: true,
  minimumTouchDrag: 10,
  renderButtonGroupOutside: false,
  renderDotsOutside: true,
  showDots: false,
  sliderClass: '',
  slidesToSlide: 1,
  swipeable: true,
  responsive: {
    tablet: {
      breakpoint: { max: 1024, min: 728 },
      items: 3,
      partialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: { max: 728, min: 380 },
      items: 2,
      partialVisibilityGutter: 30,
    },
    mobile2: {
      breakpoint: { max: 380, min: 290 },
      items: 2,
      partialVisibilityGutter: 20,
    },
    mobile3: {
      breakpoint: { max: 290, min: 0 },
      items: 1,
      partialVisibilityGutter: 80,
    },
  },
};

export type UNBOXING_CAROUSEL_PROPS = typeof UNBOXING_CAROUSEL_PROPS;

export const responsiveComingSoon = {
  mobile: {
    breakpoint: {
      max: 321,
      min: 0,
    },
    items: 2,
    partialVisibilityGutter: 40,
  },
  mobile2: {
    breakpoint: {
      max: 360,
      min: 320,
    },
    items: 2,
    partialVisibilityGutter: 50,
  },
  mobile3: {
    breakpoint: {
      max: 400,
      min: 360,
    },
    items: 2,
    partialVisibilityGutter: 60,
  },
  mobile4: {
    breakpoint: {
      max: 890,
      min: 400,
    },
    items: 2,
    partialVisibilityGutter: 70,
  },
  mobile5: {
    breakpoint: {
      max: 1024,
      min: 728,
    },
    items: 1,
    partialVisibilityGutter: 80,
  },
};

export type responsiveComingSoon = typeof responsiveComingSoon;

export const moduleStatus = {
  comingsoon: 'CONTENT_STATUS_COMING_SOON',
  draft: 'CONTENT_STATUS_DRAFT',
  published: 'CONTENT_STATUS_PUBLISHED',
};

export type moduleStatus = typeof moduleStatus;

export const moduleCategory = {
  sector: 'MODULE_CATEGORY_SECTOR',
  essential: 'MODULE_CATEGORY_ESSENTIAL',
};

export type moduleCategory = typeof moduleCategory;

export type moduleCategoryKeys = keyof typeof moduleCategory | 'all';

export const MODULE_TAB_LIST = [
  {
    id: 1,
    key: 'all',
    title: 'All',
  },
  {
    id: 2,
    key: 'essential',
    title: 'Essential',
  },
  {
    id: 3,
    key: 'sector',
    title: 'Sector',
  }
];

export type MODULE_TAB_LIST = typeof MODULE_TAB_LIST;
