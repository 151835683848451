import React from 'react';
import UnboxingCardSkeleton from '../../../molecules/Skeletons/UnboxingCardSkeleton/Mobile';
import { StyledUnboxingSkeleton } from './Mobile.styled';

const SkeletonUnboxingList = () => {
  const { ChipWrapper, Chip } = StyledUnboxingSkeleton;
  return (
    <div>
      <StyledUnboxingSkeleton data-testid={'unboxing-loading-mobile'}>
        <ChipWrapper>
          <Chip width="47px" />
          <Chip width="67px" />
          <Chip width="69px" />
        </ChipWrapper>

        {[...Array(7).keys()].map((key) => {
          return (
            <UnboxingCardSkeleton key={key} />
          );
        })}
      </StyledUnboxingSkeleton>
    </div>
  );
};

export default SkeletonUnboxingList;
