import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';
import { StyledMastHead } from '../../MastHead/Desktop.styled';
import { BREAKPOINT, MAX_WIDTH_DESKTOP } from '../../../../constants/layout';

export const StyledMastHeadSkeleton = styled(StyledMastHead)`
  max-width: ${MAX_WIDTH_DESKTOP}px;
  min-width: ${BREAKPOINT}px;
  margin: 0 auto;
`;

StyledMastHeadSkeleton.Copy = styled.div`
  flex: 0 0 40%;
  padding-right: 48px;
`;

StyledMastHeadSkeleton.Item = styled.div`
  height: 20px;
  border-radius: 3px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};
`;

StyledMastHeadSkeleton.Content = styled.div`
  height: 396px;
  flex: 0 0 60%;
  overflow: hidden;
  border-radius: 8px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};
`;
