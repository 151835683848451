import styled from 'styled-components';
import { StyledMainShowcase } from '../MainShowcase/Desktop.styled';

export const StyledChapterShowcase = styled(StyledMainShowcase)`
  .react-player video {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
  }
`;

StyledChapterShowcase.Wrapper = styled(StyledMainShowcase.Wrapper)`
  a:first-of-type > div{
    border-top: 0;
  }
`;

StyledChapterShowcase.Title = styled(StyledMainShowcase.Title)`
  margin-top: 80px;
`;
