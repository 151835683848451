import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';
import { BREAKPOINT, MAX_WIDTH_DESKTOP } from '../../../../constants/layout';

export const StyledTitleMainCardSkeleton = styled.div`
  max-width: ${MAX_WIDTH_DESKTOP}px;
  min-width: ${BREAKPOINT}px;
  padding-top: 52px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

StyledTitleMainCardSkeleton.Caption = styled.div`
  height: 20px;
  border-radius: 3px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};
`;