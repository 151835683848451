import styled from 'styled-components';
import { getColor } from '../../../utils/theme';

import { StyledImageCard } from '../UnboxingEntryCard/Card/ImageCard.styled';

export const StyledUnboxingHeadline = styled.div`
`;

StyledUnboxingHeadline.Title = styled.h1`
  margin: 40px 0;
  display: flex;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.caption, theme.palette);
    return color;
  }};

  img {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }
`;

StyledUnboxingHeadline.Wrapper = styled.div`
  display: flex;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.main.background, theme.palette);
    return color;
  }};
  width: 100%;
`;

StyledUnboxingHeadline.Left = styled.section`
  display: flex;
  flex: 0 0 59.8%;
  width: 59.8%;
  align-items: flex-start;

  .m-ImageCover {
    position: relative;
    width: 100%;
    padding-top: 56.1194%; /* 376px / 670px in figma */
    position: relative;
  }

  img {
    position: absolute;
    object-fit: cover;
    object-position: center center;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

StyledUnboxingHeadline.Category = styled(StyledImageCard.Category)`
  top: 10px;
  left: 10px;
`;

StyledUnboxingHeadline.Right = styled.section`
  display: flex;
  flex: 0 0 40.2%;
  width: 40.2%;
  flex-direction: column;
  padding-left: 40px;

  h2,
  h3 {
    margin: unset;
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.caption, theme.palette);
    return color;
  }};
    font-style: normal;
    text-align: left;
  }

  h2 {
    font-family: ProximaNovaBd;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }

  h3 {
    font-family: ProximaNovaRg;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    margin-top: 8px; 
    margin-bottom: 0;
  }

  p {
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.subtext, theme.palette);
    return color;
  }};
    margin: 20px 0;
    font-size: 14px;
    line-height: 21px;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  a {
    font-family: ProximaNovaBd;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.progress.stroke, theme.palette);
    return color;
  }};
`;

StyledUnboxingHeadline.Label = styled.span`
  margin: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-family: ProximaNovaBd;
  border: ${({ theme }) => {
    const borderColor = getColor(theme.scheme.button.borderPrimary.border, theme.palette);
    return `1px solid ${borderColor}`;
  }};
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.button.borderPrimary.text, theme.palette);
    return color;
  }};
  padding: 6px 16px;
  padding-top: 7px;
  width: 60px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  border-radius: 15px;
`;
