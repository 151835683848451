import React from 'react';
import { StyledQuestionShowcase } from '../../../organisms/QuestionShowcase/Desktop.styled';
import { StyledQuizProgress } from '../../QuizProgress/Desktop.styled';
import { StyledHeadingSectionSkeleton } from '../HeadingSectionSkeleton/styled';
import { StyledQuestionSkeleton } from './Desktop.styled';

const QuestionShowcaseSkeleton = () => {
  return (
    <StyledQuestionShowcase>
      <StyledQuestionShowcase.Heading moduleId={'1'} style={{ marginBottom: '30px' }}>
        <StyledQuestionSkeleton.Progress>
          <StyledQuizProgress.Heading>
            <StyledHeadingSectionSkeleton.Item width="50%" height="20px" />
            <StyledHeadingSectionSkeleton.Item width="80%" height="20px" />
          </StyledQuizProgress.Heading>
          <StyledQuizProgress.Content>
            <StyledQuestionSkeleton.Item width="80%" height="20px" />
          </StyledQuizProgress.Content>
        </StyledQuestionSkeleton.Progress>
      </StyledQuestionShowcase.Heading>
      <StyledQuestionShowcase.Content>
        <StyledHeadingSectionSkeleton.Item width="80%" height="20px" />
        <StyledHeadingSectionSkeleton.Item width="60%" height="20px" />
        <div style={{ margin: '70px 0 60px 0' }}>
          <StyledHeadingSectionSkeleton.Item width="100%" height="52px" style={{ marginBottom: '20px' }} />
          <StyledHeadingSectionSkeleton.Item width="100%" height="52px" style={{ marginBottom: '20px' }}/>
          <StyledHeadingSectionSkeleton.Item width="100%" height="52px" />
        </div>
        <div
          style={{
            display: 'flex',
            marginLeft: 'auto',
            justifyContent: 'flex-end',
          }}
        >
          <StyledHeadingSectionSkeleton.Item width="30%" height="20px" />
        </div>
      </StyledQuestionShowcase.Content>
    </StyledQuestionShowcase>
  );
};

export default QuestionShowcaseSkeleton;
