import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';

export const StyledMainCardSkeleton = styled.div`
  display: flex;
  overflow: hidden;
  margin: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
`;

StyledMainCardSkeleton.ModuleCard = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px;
  margin-left: 0;
`;

StyledMainCardSkeleton.Image = styled.div`
  height: 170px;
  width: 294px;
  border-radius: 3px;
  margin-bottom: 16px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};
`;

StyledMainCardSkeleton.Caption = styled.div`
  border-radius: 3px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};
`;

StyledMainCardSkeleton.ChapterCard = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 16px 60px 0;
`;

StyledMainCardSkeleton.ImageChapter = styled.div`
  height: 142px;
  width: 264px;
  border-radius: 3px;
  margin-bottom: 16px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};
`;
