import React from 'react';
import { StyledUnboxingHeadline } from '../../UnboxingHeadline/styled';
import { StyledHeadingSectionSkeleton } from '../HeadingSectionSkeleton/styled';

const UnboxingHeadlineSkeleton = () => {
  const { Title, Wrapper, Left, Right } = StyledUnboxingHeadline;

  return (
    <StyledUnboxingHeadline>
      <Title>
        <StyledHeadingSectionSkeleton.Item width="114px" height="20px" borderRadius="4px" />
      </Title>
      <Wrapper>
        <Left>
          <StyledHeadingSectionSkeleton.Item width="100%" height="376px" borderRadius="0" />
        </Left>
        <Right>
          <h2>
            <StyledHeadingSectionSkeleton.Item width="50%" height="20px" borderRadius="0" margin="40px 0" />
          </h2>
          <span>
            <StyledHeadingSectionSkeleton.Item width="100%" height="20px" borderRadius="0" marginBottom="8px" />
            <StyledHeadingSectionSkeleton.Item width="100%" height="20px" borderRadius="0" marginBottom="8px" />
            <StyledHeadingSectionSkeleton.Item width="50%" height="20px" borderRadius="0" marginBottom="8px" />
          </span>
        </Right>
      </Wrapper>
    </StyledUnboxingHeadline>
  );
};

export default UnboxingHeadlineSkeleton;
