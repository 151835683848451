import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Normalize } from 'styled-normalize';
import { ThemeProvider } from 'styled-components';
import useTheme from '../../../hooks/useTheme';
import { GlobalStyles } from './styled';

const ThemeWrapper = ({ children }) => {
  const location = useLocation();
  const { palette, scheme, themeLoaded, name } = useTheme(location);
  const theme = { palette, scheme, name };

  if (!themeLoaded) {
    return <>{children}</>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Normalize />
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

ThemeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeWrapper;
