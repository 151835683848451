import React from 'react';

import { StyledUnboxingEntrySkeleton } from './Mobile.styled';

const UnboxingEntrySkeleton = () => {
  return (
    <StyledUnboxingEntrySkeleton>
      {[1, 2, 3].map((key) => (
        <StyledUnboxingEntrySkeleton.Box key={key} />
      ))}
    </StyledUnboxingEntrySkeleton>
  );
};

export default UnboxingEntrySkeleton;