import getVideoId from 'get-video-id';
import { DEFAULT_IMAGE_PLACEHOLDER } from '../constants/layout';
import { moduleCategory } from '../constants/module';

/**
 * Copy text to clipboard
 * @param {string} text
 */
export const copyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea');

  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if the element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won't even render, not even a
  // flash, so some of these are just precautions. However in
  // Internet Explorer the element is visible whilst the popup
  // box asking the user for permission for the web page to
  // copy to the clipboard.
  //

  // Place in the top-left corner of screen regardless of scroll position.
  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em';
  textArea.style.height = '2em';

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = 0;

  // Clean up any borders.
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';

  // Avoid flash of the white box if rendered for any reason.
  textArea.style.background = 'transparent';

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    /* eslint-disable */
    console.log('error');
  }

  document.body.removeChild(textArea);
};

/**
 * Turn #000000 to rgba(0, 0, 0, 1);
 * @param {string} hex - 6 characters that defines color (#fff causes error)
 * @param {number} alpha - Transparancy (0 - 1)
 * @returns {string}
 */
export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

/**
 * Get Thumbnail from YouTube Link
 * @param {string} videoLink - YouTube link
 */
export const getYoutubeThumbnail = (videoLink) => {
  const { id } = getVideoId(videoLink);
  const youtubeThumbnail = `https://img.youtube.com/vi/${id}/maxresdefault.jpg`;
  return youtubeThumbnail;
};

/**
 * Decide which one to use (cover image or YouTube thumbnail)
 * @param {Object} data - { cover_img, video_link }
 */
export const getPreviewImage = ({ cover_img, video_link }) => {
  // Use placeholder.
  if (typeof cover_img === 'string' && cover_img.length > 0 && typeof video_link !== 'string') {
    return DEFAULT_IMAGE_PLACEHOLDER;
  }

  // Use placeholder.
  if (cover_img.length === 0 && (video_link.length === 0)) {
    return DEFAULT_IMAGE_PLACEHOLDER;
  }

  // Both exist, but we prefer cover image instead of YouTube thumbnail
  if (cover_img.length > 0 && video_link.length > 0) {
    return cover_img;
  }

  // Only cover image available, therefore show it for all I care
  if (cover_img.length > 0 && video_link.length === 0) {
    return cover_img;
  }

  // Only video link available, therefore show it for all I care
  if (cover_img.length === 0 && video_link.length > 0) {
    return getYoutubeThumbnail(video_link);
  }
};

/**
 * Get next step after finishing quiz and before starting quiz
 * @param {number} chapterId - Current chapter ID
 * @param {number} moduleId - Current module ID
 * @param {boolean} isDesktop - Is it used on desktop?
 * @param {array of Object} modules - Modules data from Redux.
 * @returns  {Object} - { link, wording }
 */
export const getNextStep = (chapterId, moduleId, modules, isDesktop) => {
  let chapters = [];

  modules.forEach((module) => {
    chapters = chapters.concat(
      module.chapters.slice().sort((a, b) => a.id - b.id),
    );
  });

  const currentChapterIndex = chapters.findIndex(
    (chapter) => chapter.id === chapterId,
  );

  const nextChapter = chapters[currentChapterIndex + 1];

  const step = {
    link: '',
    wording: '',
  };

  if (nextChapter === undefined) {
    step.link = '/';
    step.wording = 'Kembali ke Halaman Utama';
  }

  if (nextChapter && nextChapter.module_id !== moduleId) {
    step.link = `/modules/${nextChapter.module_id}`;
    step.wording = 'Module Berikutnya';
  }

  if (nextChapter && nextChapter.module_id === moduleId) {
    step.link = isDesktop
      ? `/modules/${nextChapter.module_id}/chapters/${nextChapter.id}/lessons`
      : `/modules/${nextChapter.module_id}/chapters/${nextChapter.id}`;
    step.wording = isDesktop
      ? 'Lanjut ke Chapter Berikutnya'
      : 'Chapter Berikutnya';
  }

  return step;
};

/**
 * @param {string} category - Module category raw format
 * @return {string} - Adjusted category string
 */
export const getModuleCategory = (category) => {
  if (!category) return;

  let modCategory = '';
  if (category === moduleCategory.sector) {
    modCategory = 'Sector';
  } else if (category === moduleCategory.essential) {
    modCategory = 'Essential';
  }

  return modCategory;
}
