import styled from 'styled-components';
import { getColor } from '../../../utils/theme';
import { StyledLastRead } from '../LastRead/Mobile.styled';

export const StyledBroadcastCard = styled(StyledLastRead)`
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;
  height: 109px;
`;

StyledBroadcastCard.TeaserWrapper = styled(StyledLastRead.TeaserWrapper)`
  flex: 0 0 50%;
`;

StyledBroadcastCard.Teaser = styled(StyledLastRead.Teaser)`
  width: 100%;
  height: 90px;
  position: relative;
  overflow: hidden;
  padding-bottom: 56.2%;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
`;

StyledBroadcastCard.ContentWrapper = styled(StyledLastRead.ContentWrapper)`
  flex: 1;
  gap: 4px;
`;

StyledBroadcastCard.Ribbon = styled.div`
  background: ${({ theme }) => {
    const color = getColor(
      theme.scheme.button.primary.background,
      theme.palette,
    );
    return color;
  }};
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.button.primary.text, theme.palette);
    return color;
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  width: 70px;
  padding-top: 13px;
  padding-bottom: 5px;
  position: absolute;
  transform: rotate(45deg);
  top: -5px;
  right: -25px;
`;
