import styled from 'styled-components';
import { getColor } from '../../../utils/theme';

export const StyledPagination = styled.section`
  position: fixed;
  bottom: 0;
  height: 44px;
  width: 100%;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.header.background, theme.palette);
    return color;
  }};
  border-top: ${({ theme }) => {
    const color = getColor(theme.scheme.main.border, theme.palette);
    return `1px solid ${color};`;
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 20px;

  color: ${({ theme }) => {
    const color = getColor(theme.scheme.content.subtext, theme.palette);

    return color;
  }};

  svg {
    fill: ${({ theme }) => {
    const color = getColor(theme.scheme.content.subtext, theme.palette);

    return color;
  }};
  }

  ${({ isDesktop }) => {
    if (isDesktop) {
      return `
        border-top: unset;
        position: relative;
        justify-content: flex-start;
      `;
    }
  }}

  .active {
    margin-left: 40px;
    margin-right: 8px;
  }

  .total {
    margin-right: 40px;
    margin-left: 5px;
  }

  .active {
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.content.desc, theme.palette);

    return color;
  }};
    font-family: ProximaNovaBd;
  }
`;
