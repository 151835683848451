import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';

export const StyledLeafletSkeleton = styled.div``;

StyledLeafletSkeleton.Content = styled.div`
  display: flex;
  flex-direction: column;
`;

StyledLeafletSkeleton.Video = styled.div`
  width: 100%;
  height: 428px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);
    return color;
  }};
`;

StyledLeafletSkeleton.Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 44px;
  border-bottom: ${({ theme }) => {
    const color = getColor(theme.scheme.content.border, theme.palette);
    return `1px solid ${color};`;
  }};
`;

StyledLeafletSkeleton.Caption = styled.div`
  height: 20px;
  border-radius: 3px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);
    return color;
  }};
`;

StyledLeafletSkeleton.Text= styled.div`
  padding: 30px 44px;
  margin-bottom: 140px;
`;

StyledLeafletSkeleton.Pagination= styled.div`
  padding: 0 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;