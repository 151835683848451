import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledLoadingRing = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  animation: ${rotate} 1.2s cubic-bezier(0.7, 0, 0.7, 1) infinite;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    border-color: #00ab6b #00ab6b #00ab6b transparent;

    &:nth-child(1) {
      animation-delay: -0.35s;
    }

    &:nth-child(2) {
      animation-delay: -0.2s;
    }

    &:nth-child(3) {
      animation-delay: -0.05s;
    }
  }
`;
