import React, { useEffect } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import { getIsExpired, getTimestamp } from '../../../utils/auth';
import postMessage from '../../../utils/postMessage';

const AuthWrapper = ({ renderProps }) => {
  const location = useLocation();
  const history = useHistory();
  const isGranted = useAuth(location, history);
  const timestamp = getTimestamp();
  const isExpired = getIsExpired(timestamp);

  const handlePopState = () => {
    const message = {
      fn: 'popstate',
      data: {
        href: window.location.href
      }
    };

    postMessage(JSON.stringify(message));
  };

  useEffect(() => {
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  if (isExpired) {
    return (
      <Redirect to="/reload" />
    );
  }

  return (
    <>
      {renderProps({
        isGranted,
      })}
    </>
  );
};

AuthWrapper.propTypes = {
  renderProps: PropTypes.func.isRequired,
};

export default AuthWrapper;
