import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';
import { DEVICE } from '../../../../constants/layout';

export const StyledUnboxingEntrySkeleton = styled.div`
  min-height: 180px;
  padding: 20px;
  display: flex;
  padding-right: 0;
  overflow: hidden;
`;

StyledUnboxingEntrySkeleton.Box = styled.div`
  border-radius: 8px;
  min-width: 140px;
  min-height: 140px;
  margin-right: 12px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);
    return color;
  }};

  @media ${DEVICE.tablet} {
    min-height: 220px;
    min-width: 220px;
    margin-right: 20px;
  }
`;
