import React, { lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BREAKPOINT } from '../../../constants/layout';
import useViewport from '@hooks/useViewport';
import {
  Desktop as SkeletonForDesktop,
  Mobile as SkeletonForMobile
} from '../../organisms/Skeletons/MainShowcase';

const Desktop = lazy(() => import('./Desktop'));
const Mobile = lazy(() => import('./Mobile'));

const Home = ({ isGranted }) => {
  const { width } = useViewport();
  const [load, setLoad]  = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, 300);
  }, []);

  if (!load) {
    return null;
  }

  return (
    <Suspense fallback={width < BREAKPOINT ? <SkeletonForMobile isGranted={isGranted} /> : <SkeletonForDesktop isGranted={isGranted} />}>
      {width < BREAKPOINT ? (
        <Mobile isGranted={isGranted} />
      ) : (
        <Desktop isGranted={isGranted} />
      )}
    </Suspense>
  );
};

Home.propTypes = {
  isGranted: PropTypes.bool.isRequired,
};

export default Home;
