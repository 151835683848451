import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Auth from '../../../pages/Auth';
import Home from '../../../pages/Home';
import Explore from '../../../pages/Explore';
import Module from '../../../pages/Module';
import Chapter from '../../../pages/Chapter';
import Lesson from '../../../pages/Lesson';
import Quiz from '../../../pages/Quiz';
import Error from '../../../pages/Error';
import News from '../../../pages/News';
import ReadingPlatform from '../../../pages/ReadingPlatform';
import Refresh from '../../../pages/Refresh';
import Reload from '../../../pages/Reload';
import UnboxingList from '../../../pages/UnboxingList';
import UnboxingDetails from '../../../pages/UnboxingDetails';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/auth">
        <Auth />
      </Route>
      <Route exact path="/exit">
        <Error code="401" />
      </Route>
      <Route exact path="/401">
        <Error code="401" />
      </Route>
      <Route exact path="/403">
        <Error code="403" />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/refresh">
        <Refresh />
      </Route>
      <Route exact path="/reload">
        <Reload />
      </Route>
      <Route exact path="/unboxing/:reportId">
        <UnboxingDetails />
      </Route>
      <Route exact path="/explore">
        <Explore />
      </Route>
      <Route exact path="/news">
        <News />
      </Route>
      <Route exact path="/unboxing">
        <UnboxingList />
      </Route>
      <Route exact path="/modules/:moduleId">
        <Module />
      </Route>
      <Route exact path="/modules/:moduleId/chapters/:chapterId">
        <Chapter />
      </Route>
      <Route exact path="/modules/:moduleId/chapters/:chapterId/lessons">
        <ReadingPlatform />
      </Route>
      <Route
        exact
        path="/modules/:moduleId/chapters/:chapterId/lessons/:lessonId"
      >
        <Lesson />
      </Route>
      <Route exact path="/modules/:moduleId/chapters/:chapterId/quiz">
        <Quiz />
      </Route>
      <Route path="*">
        <Error code="404" />
      </Route>
    </Switch>
  );
}
