import styled from 'styled-components';
import { getColor } from '../../../utils/theme';
import { Link } from 'react-router-dom';

import { StyledImageCard } from '../UnboxingEntryCard/Card/ImageCard.styled';

export const StyledUnboxingCard = styled.article`
  display: flex;
  flex-direction: column;

  /* Aspect ratio 4:3 */
  /* Go see https://www.w3schools.com/howto/howto_css_aspect_ratio.asp */
  .m-ImageCover {
    width: 100%;
    padding-top: 75%;
    position: relative;
  }

  img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

StyledUnboxingCard.Wrapper = styled(Link)`
  display: block;
`;

StyledUnboxingCard.Category = styled(StyledImageCard.Category)`
  top: 10px;
  left: 10px;
`;

StyledUnboxingCard.LinkText = styled.p`
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.link, theme.palette);
    return `${color} !important`;
  }};
`;

StyledUnboxingCard.Content = styled.div`
  h2,
  h3 {
    margin: unset;
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};
  }

  h2 {
    font-weight: bold;
    font-family: ProximaNovaBd;
    font-size: 20px;
    line-height: 27px;
    margin-top: 16px;
  }

  h3 {
    font-family: ProximaNovaRg;
    font-weight: normal;
    margin: 8px 0;
    font-size: 12px;
    line-height: 16px;
  }

  p {
    margin: unset;
    margin: 12px 0;
    display: -webkit-box;
    font-size: 14px;
    line-height: 21px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.subtext, theme.palette);
    return color;
  }};
  }

  a {
    font-family: ProximaNovaRg;
    font-size: 14px;
    line-height: 19px;
    margin-top: 12px;
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.progress.stroke, theme.palette);
    return color;
  }};
  }
`;
