import React, { lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useViewport from '@hooks/useViewport';
import { Redirect, useParams } from 'react-router-dom';
import { BREAKPOINT } from '../../../constants/layout';
import SkeletonLessonShowcase from '../../organisms/Skeletons/LessonShowcase';

const Mobile = lazy(() => import('./Mobile'));

const Chapter = ({ isGranted }) => {
  const { moduleId, chapterId } = useParams();
  const { width } = useViewport();
  const [load, setLoad]  = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, 300);
  }, []);

  // Redirect to reading platform on desktop if viewport touches BREAKPOINT
  if (width >= BREAKPOINT) {
    return (
      <Redirect to={`/modules/${moduleId}/chapters/${chapterId}/lessons`} />
    );
  }

  if (!load) {
    return null;
  }

  return (
    <Suspense fallback={<SkeletonLessonShowcase />}>
      <Mobile isGranted={isGranted} />
    </Suspense>
  );
};

Chapter.propTypes = {
  isGranted: PropTypes.bool.isRequired,
};

export default Chapter;
