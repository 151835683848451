import React, { lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BREAKPOINT } from '../../../constants/layout';
import useViewport from '@hooks/useViewport';
import { Redirect, useLocation } from 'react-router-dom';
import SkeletonModuleShowcase from '../../organisms/Skeletons/ModuleShowcase';
import { onAcademyExploreModuleViewed } from '../../../utils/clevertapAnalytics';
import { sourceMapper } from '../../../constants/analytics';

const Mobile = lazy(() => import('./Mobile'));

const Explore = ({ isGranted }) => {
  const { width } = useViewport();
  const [load, setLoad]  = useState(false);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, 300);
  }, []);

  useEffect(() => {
    const prevPath = location?.state?.prevPath;
    const sourceKey = prevPath || 'academy';
    onAcademyExploreModuleViewed(sourceMapper[sourceKey]);
  }, []);

  if (!load) {
    return null;
  }

  // Redirect to home page on desktop if viewport touches BREAKPOINT
  if (width > BREAKPOINT) {
    return <Redirect to="/" />;
  }

  return (
    <Suspense fallback={<SkeletonModuleShowcase />}>
      <Mobile isGranted={isGranted} />
    </Suspense>
  );
};

Explore.propTypes = {
  isGranted: PropTypes.bool.isRequired,
};

export default Explore;
