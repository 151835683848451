import React from 'react';
import { StyledReadingPlatform } from '../../../templates/ReadingPlatform/Desktop.styled';
import BreadcrumbSkeleton from '../../../molecules/Skeletons/BreadcrumbSkeleton';
import LeafletSkeleton from '../../../molecules/Skeletons/LeafletSkeleton/Desktop';
import LessonShowcaseSkeleton from '../../../molecules/Skeletons/LessonShowcaseSkeleton/Desktop';

const SkeletonReadingPlatform = () => {
  return (
    <StyledReadingPlatform data-testid="reading-platform-skeleton">
      <StyledReadingPlatform.Wrapper>
        <BreadcrumbSkeleton />
      </StyledReadingPlatform.Wrapper>
      <StyledReadingPlatform.Wrapper>
        <StyledReadingPlatform.LeafletWrapper className="o-leafletWrapper">
          <LeafletSkeleton/>
        </StyledReadingPlatform.LeafletWrapper>
        <StyledReadingPlatform.LessonShowcaseWrapper className="o-lessonShowcaseWrapper">
          <LessonShowcaseSkeleton/>
        </StyledReadingPlatform.LessonShowcaseWrapper>
      </StyledReadingPlatform.Wrapper>
    </StyledReadingPlatform>
  );
};

export default SkeletonReadingPlatform;
