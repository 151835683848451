import React from 'react';
import { StyledNewsSkeleton } from './styled';

const NewsSkeleton = () => {
  return (
    <StyledNewsSkeleton to="#">
      {[1, 2, 3].map((key) => {
        return <StyledNewsSkeleton.Card to="#" order={key} key={key} />;
      })}
      <StyledNewsSkeleton.Item width="48px" height="12px" />
    </StyledNewsSkeleton>
  );
};

export default NewsSkeleton;
