import styled from 'styled-components';
import { getColor } from '../../../utils/theme';

export const StyledLoading = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => {
    if (theme && theme.scheme) {
      const color = getColor(theme.scheme.main.background, theme.palette);

      return color;
    }
  }};

  * {
    color: ${({ theme }) => {
    if (!theme.scheme || !theme.palette) {
      return null;
    }

    const color = getColor(theme.scheme.main.text, theme.palette);

    return color;
  }};
  }
`;
