import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { StyledButton, StyledButtonNonLink } from './styled';

const Button = forwardRef(({
  type,
  height,
  width,
  fontWeight,
  fontSize,
  children,
  borderRadius,
  onClick,
  ripple,
  to,
  style,
  padding,
  margin,
}, ref) => {
  if (to && to !== '#') {
    return (
      <StyledButton
        fontWeight={fontWeight}
        fontSize={fontSize}
        type={type}
        height={height}
        width={width}
        borderradius={borderRadius}
        to={to}
        onClick={onClick}
        padding={padding}
        margin={margin}
        ref={ref}
      >
        {children}
      </StyledButton>
    );
  }

  return (
    <StyledButtonNonLink
      ripple={ripple}
      fontWeight={fontWeight}
      fontSize={fontSize}
      type={type}
      height={height}
      width={width}
      onClick={onClick}
      style={style}
      padding={padding}
      margin={margin}
      ref={ref}
    >
      {children}
    </StyledButtonNonLink>
  );
});

Button.propTypes = {
  type: PropTypes.string.isRequired,
  height: PropTypes.string,
  fontWeight: PropTypes.string,
  fontSize: PropTypes.string,
  width: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  ripple: PropTypes.bool,
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  padding: PropTypes.string,
  margin: PropTypes.string,
};

Button.defaultProps = {
  height: '44px',
  width: '100%',
  fontWeight: 'bold',
  fontSize: '18px',
  children: null,
  to: '#',
  onClick: () => { },
  ripple: false,
  borderRadius: '4px',
};

Button.displayName = 'Button';

export default Button;
