import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { StyledTopNavbar } from './Mobile.styled';
import { ChevronLeft } from '@styled-icons/bootstrap';
import Image from '../../atoms/Image';
import Share from '../Share';
import { handleAndroidDeviceBack } from '../../../utils/postMessage';

const TopNavbar = ({
  backLink,
  copyLink,
  title,
  noTitle,
  transparent,
  prevPath,
  isLoading,
}) => {
  const history = useHistory();

  useEffect(() => {
    window.addEventListener(
      'AcademyJSCustomEvent',
      handleAndroidDeviceBack(history, backLink),
    );
    return () => {
      window.removeEventListener(
        'AcademyJSCustomEvent',
        handleAndroidDeviceBack(history, backLink),
      );
    };
  }, []);

  const displayedTitle = !noTitle ? title || 'Stockbit Academy' : '';

  const handleBack = (e) => {
    e.preventDefault();
    if (prevPath && typeof history.goBack === 'function') {
      history.go(-1);
    } else {
      history.push(backLink);
    }
  };

  return (
    <StyledTopNavbar>
      <StyledTopNavbar.Content transparent={transparent}>
        <StyledTopNavbar.BackButton onClick={handleBack} to={backLink}>
          <ChevronLeft size={16} />
        </StyledTopNavbar.BackButton>
        {isLoading ? <StyledTopNavbar.TitleSkeleton /> : displayedTitle}
        {copyLink ? (
          <StyledTopNavbar.CopyLink>
            <Share
              shareLink={`/academy${copyLink}`}
              trigger={
                <Image
                  src={`${process.env.PUBLIC_URL}/img/copy-link.svg`}
                  alt="locked"
                  width={14}
                  height={24}
                />
              }
            />
          </StyledTopNavbar.CopyLink>
        ) : null}
      </StyledTopNavbar.Content>
    </StyledTopNavbar>
  );
};

TopNavbar.propTypes = {
  backLink: PropTypes.string,
  copyLink: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  title: PropTypes.string,
  noTitle: PropTypes.bool,
  transparent: PropTypes.bool,
  prevPath: PropTypes.bool,
  isLoading: PropTypes.bool,
};

TopNavbar.defaultProps = {
  backLink: '/exit',
  copyLink: null,
  title: 'Stockbit Academy',
  noTitle: false,
  transparent: false,
  prevPath: false,
  isLoading: false,
};

export default TopNavbar;
