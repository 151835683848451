import React from 'react';
import PropTypes from 'prop-types';
import { StyledMainCardSkeleton } from './Mobile.styled';

const MainCardSkeleton = ({ order }) => {
  return (
    <StyledMainCardSkeleton order={order} to="/">
      <StyledMainCardSkeleton.ModuleCard>
        <StyledMainCardSkeleton.Image />
        <StyledMainCardSkeleton.Caption
          style={{
            width: '144px',
            height: '12px',
            marginBottom: '8px'
          }}
        />
        <StyledMainCardSkeleton.Caption
          style={{
            width: '72px',
            height: '12px'
          }}
        />
      </StyledMainCardSkeleton.ModuleCard>

      <StyledMainCardSkeleton.ChapterCard>
        <StyledMainCardSkeleton.ImageChapter />
        <StyledMainCardSkeleton.Caption
          style={{
            width: '144px',
            height: '12px',
            marginBottom: '8px'
          }}
        />
        <StyledMainCardSkeleton.Caption
          style={{
            width: '72px',
            height: '12px'
          }}
        />
      </StyledMainCardSkeleton.ChapterCard>
      <StyledMainCardSkeleton.ChapterCard>
        <StyledMainCardSkeleton.ImageChapter />
        <StyledMainCardSkeleton.Caption
          style={{
            width: '144px',
            height: '12px',
            marginBottom: '8px'
          }}
        />
        <StyledMainCardSkeleton.Caption
          style={{
            width: '72px',
            height: '12px'
          }}
        />
      </StyledMainCardSkeleton.ChapterCard>
    </StyledMainCardSkeleton>
  );
};

MainCardSkeleton.propTypes = {
  order: PropTypes.number.isRequired,
};


export default MainCardSkeleton;
