import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';
import { StyledLessonItem } from '../../LessonItem/styled';
import { StyledHeadingSectionSkeleton } from '../HeadingSectionSkeleton/styled';

export const StyledLessonItemSkeleton = styled(StyledLessonItem)``;
StyledLessonItemSkeleton.MainSection = styled(StyledLessonItem.MainSection)``;
StyledLessonItemSkeleton.Label = styled(StyledLessonItem.Label)`
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};
  border-radius: 3px;
`;
StyledLessonItemSkeleton.Heading = styled(StyledLessonItem.Heading)``;

StyledLessonItemSkeleton.Item = styled(StyledHeadingSectionSkeleton.Item)`
  border-radius: 3px;
`;
