import React from 'react';
import PropTypes from 'prop-types';
import { StyledModuleCardSkeleton } from './styled';

const ModuleCardSkeleton = ({ order }) => {
  return (
    <StyledModuleCardSkeleton order={order} to="/">
      <StyledModuleCardSkeleton.Header order={order} />
      <StyledModuleCardSkeleton.Content>
        <StyledModuleCardSkeleton.Item width="60%" height="12px" />
        <StyledModuleCardSkeleton.Item width="40%" height="12px" />
      </StyledModuleCardSkeleton.Content>
    </StyledModuleCardSkeleton>
  );
};

ModuleCardSkeleton.propTypes = {
  order: PropTypes.number.isRequired,
};

export default ModuleCardSkeleton;
