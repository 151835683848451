import React from 'react';
import { StyledLastReadCardSkeleton } from './Mobile.styled';

const LastReadCardSkeleton = () => {
  return (
    <StyledLastReadCardSkeleton to="/"> 
      <StyledLastReadCardSkeleton.Card />
    </StyledLastReadCardSkeleton>
  );
};

export default LastReadCardSkeleton;