export const SHARED_LINK = 'SHARED_LINK';
export const CURRENT_LESSON_ID_AS_VISITOR = 'CURRENT_LESSON_ID_AS_VISITOR';
export const CURRENT_CHAPTER_ID_AS_VISITOR = 'CURRENT_CHAPTER_ID_AS_VISITOR';
export const DEFAULT_SHARED_LINK = {
  moduleId: 1,
  chapterId: 4,
  lessonId: 1,
  link: '/modules/1/chapters/4/lessons/1',
  unlockedLessonIds: [1],
};

export const DEFAULT_SHARED_LINK_ON_DESKTOP = {
  moduleId: 1,
  chapterId: 4,
  lessonId: 0,
  link: '/modules/1/chapters/4/lessons',
  unlockedLessonIds: [],
};
