import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getColor } from '../../../utils/theme';

export const StyledButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  line-height: 22px;
  text-decoration: none;
  border-radius: 4px;
  font-family: ProximaNovaBd;
  ${({
    theme,
    type,
    fontSize,
    fontWeight,
    height,
    width,
    margin,
    borderradius,
  }) => {
    const scheme = theme.scheme.button[type];
    const textColor = getColor(scheme.text, theme.palette);
    const backgroundColor = getColor(scheme.background, theme.palette);
    const borderColor = getColor(scheme.border, theme.palette);
    const fontFamily =
      fontWeight === 'bold' ? 'ProximaNovaBd' : 'ProximaNovaRg';

    return `
      color: ${textColor};
      background: ${backgroundColor};
      border: 1px solid ${borderColor};
      font-size: ${fontSize ? fontSize : '18px'};
      font-family: ${fontFamily};
		  height: ${height ? height : '44px'};
			width: ${width ? width : '100%'};
      border-radius: ${borderradius ? borderradius : '4px'};
      margin: ${margin || '0px'};
    `;
  }};
`;

export const StyledButtonNonLink = styled.button`
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  line-height: 22px;
  cursor: pointer;
  text-decoration: none;
  font-family: ProximaNovaBd;
  border-radius: 4px;
  ${({
    theme,
    type,
    fontSize,
    fontWeight,
    height,
    width,
    borderRadius,
    padding,
  }) => {
    const scheme = theme.scheme.button[type];
    const textColor = getColor(scheme.text, theme.palette);
    const backgroundColor = getColor(scheme.background, theme.palette);
    const borderColor = getColor(scheme.border, theme.palette);
    const fontFamily =
      fontWeight === 'bold' ? 'ProximaNovaBd' : 'ProximaNovaRg';

    return `
      color: ${textColor};
      background: ${backgroundColor};
      border: 1px solid ${borderColor};
      font-size: ${fontSize ? fontSize : '18px'};
      font-family: ${fontFamily};
		  height: ${height ? height : '44px'};
			width: ${width ? width : '100%'};
      border-radius: ${borderRadius ? borderRadius : '4px'};
      padding: ${padding || '1rem 2rem'};
    `;
  }};

  background-position: center;
  transition: background 0.8s;

  ${({ ripple }) => {
    if (ripple) {
      return `
        &:hover {
          background: rgba(0, 0, 0, 0.1) radial-gradient(circle, transparent 1%, #fff 1%)
            center/15000%;
        }

        &:active {
          background-size: 100%;
          transition: background 0s;
        }
      `;
    }
  }}
`;
