import styled from 'styled-components';
import { getColor } from '../../../utils/theme';
import { DEVICE } from '../../../constants/layout';

export const StyledMainShowcase = styled.div`
  margin-bottom: 40px;
  padding-top: 44px;

  @font-face {
    font-family: 'revicons';
    fallback: fallback;
    src: url('./revicons.woff') format('woff'),
      url('./revicons.ttf') format('ttf'), url('./revicons.eot') format('ttf');
  }
  .react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  .react-multi-carousel-track {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform, transition;
    height: 100%;
  }
  .react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all 0.5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: rgba(0, 0, 0, 0.5);
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    cursor: pointer;
  }
  .react-multiple-carousel__arrow:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  .react-multiple-carousel__arrow::before {
    font-size: 20px;
    color: #fff;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
  }
  .react-multiple-carousel__arrow:disabled {
    cursor: default;
    background: rgba(0, 0, 0, 0.5);
  }
  .react-multiple-carousel__arrow--left {
    left: calc(4% + 1px);
  }
  .react-multiple-carousel__arrow--left::before {
    content: '\e824';
  }
  .react-multiple-carousel__arrow--right {
    right: calc(4% + 1px);
  }
  .react-multiple-carousel__arrow--right::before {
    content: '\e825';
  }
  .react-multi-carousel-dot-list {
    position: absolute;
    bottom: 0;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    margin: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .react-multi-carousel-dot button {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: 1;
    padding: 5px 5px 5px 5px;
    box-shadow: none;
    transition: background 0.5s;
    border-width: 2px;
    border-style: solid;
    border-color: grey;
    padding: 0;
    margin: 0;
    margin-right: 4px;
    outline: 0;
    cursor: pointer;
  }
  .react-multi-carousel-dot button:hover:active {
    background: #080808;
  }
  .react-multi-carousel-dot--active button {
    background: #080808;
  }
  .react-multi-carousel-item {
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .react-multi-carousel-item {
      flex-shrink: 0 !important;
    }
    .react-multi-carousel-track {
      overflow: visible !important;
    }
  }
`;

StyledMainShowcase.Wrapper = styled.div`
  margin: 20px;
`;

StyledMainShowcase.Title = styled.h2`
  font-size: 14px;
  @media ${DEVICE.tablet} {
    font-size: 16px;
  }
  height: 40px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  margin: 0;
  padding: 0 20px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.main.background, theme.palette);
    return color;
  }};
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};
  ${({ theme }) => {
    const color = getColor(theme.scheme.unboxing.border, theme.palette);
    return `border-bottom: 1px solid ${color};`;
  }}

  span {
    font-size: 14px;
    font-family: ProximaNovaBd;
    @media ${DEVICE.tablet} {
      font-size: 16px;
    }
  }

  a {
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.link, theme.palette);
    return color;
  }};

    span {
      font-size: 14px;
      font-family: ProximaNovaRg;
    }
    &:hover,
    &:active {
      color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.link, theme.palette);
    return color;
  }};
    }
  }
`;

StyledMainShowcase.SliderContainer = styled.div`
  position: relative;
  margin-bottom: 80px;
  ${({ isOnHeader, isComingSoon }) => {
    if (isOnHeader) {
      return `
          margin-bottom: 60px;
        `;
    }

    if (isComingSoon) {
      return `
          margin-bottom: 20px;
        `;
    }
  }}

  .react-multi-carousel-dot {
    list-style: none;
    margin: unset;
    padding: unset;
    height: 6px;
    display: flex;
    overflow: hidden;
    margin-top: 30px;
    bottom: unset;
    border-radius: 6px;
  }

  .react-multi-carousel-dot button {
    width: 6px;
    height: 6px;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    border-radius: 6px;
    background: ${({ theme }) => {
    const color = getColor(theme.scheme.slider.default, theme.palette);
    return color;
  }};
  }

  .react-multi-carousel-dot--active {
    button {
      width: 12px;
      background: ${({ theme }) => {
    const key = theme.scheme.progress.stroke;
    const color = getColor(key, theme.palette);
    return color;
  }};
    }
  }

  .react-multi-carousel-dot-list {
    bottom: unset;
  }

  .topSlider .react-multi-carousel-item,
  .topSlider img {
    max-width: 100%;
    height: auto;
  }

  .topSlider .react-multi-carousel-item section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .player-wrapper {
    padding-top: 56.25%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .react-multi-carousel-list {
    margin-left: 20px;
    margin-top: 20px;
    ${({ isOnHeader }) => {
    if (isOnHeader) {
      return `
          margin-top: 0;
          margin-left: 0;
        `;
    }
  }}
  }

  .react-multi-carousel-item {
    display: flex;
    padding-right: 16px;
    ${({ isOnHeader }) => {
    if (isOnHeader) {
      return `
          padding-right: 0;
        `;
    }
  }}

    &:first-child > a > article {
      padding-top: 22px;
    }

    &:last-child {
      padding-right: 20px;
      ${({ isOnHeader }) => {
    if (isOnHeader) {
      return `
            padding-right: 0;
          `;
    }
  }}
    }
  }
`;

StyledMainShowcase.Unboxing = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;
