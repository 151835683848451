import React from 'react';
import TopNavbar from '../../../molecules/TopNavbar/Mobile';
import { StyledChapterShowcase } from '../../../organisms/ChapterShowcase/Mobile.styled';
import LessonShowcaseSkeleton from '../../../molecules/Skeletons/LessonShowcaseSkeleton/Mobile';
import MastHeadSkeleton from '../../../molecules/Skeletons/MastHeadSkeleton/Mobile';
import TitleChapterSkeleton from '../../../molecules/Skeletons/TitleChapterSkeleton';

const SkeletonLessonShowcase = () => {
  return (
    <div>
      <TopNavbar title="" backLink="/" />
      <StyledChapterShowcase>
        <MastHeadSkeleton/>
        <StyledChapterShowcase.Wrapper>
          <StyledChapterShowcase.Copy>
            <TitleChapterSkeleton/>
          </StyledChapterShowcase.Copy>
        </StyledChapterShowcase.Wrapper>
        <LessonShowcaseSkeleton />
      </StyledChapterShowcase>
    </div>
  );
};

export default SkeletonLessonShowcase;
