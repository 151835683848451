import React from 'react';
import { StyledLeafletSkeleton } from './Mobile.styled';

const LeafletSkeleton = () => {
  return (
    <div style={{ margin: 20 }} data-testid="leaflet-skeleton">
      <StyledLeafletSkeleton>
        <StyledLeafletSkeleton.Title>
          <h3 className="title">
            <StyledLeafletSkeleton.Item width="55%" height="20px" />
          </h3>
        </StyledLeafletSkeleton.Title>
        <div style={{ marginBottom: 25 }} />
        <StyledLeafletSkeleton.Title>
          <StyledLeafletSkeleton.Item width="80%" height="12px" />
          <StyledLeafletSkeleton.Item width="80%" height="12px" />
          <StyledLeafletSkeleton.Item width="30%" height="12px" />
          <div style={{ marginBottom: 25 }} />
          <StyledLeafletSkeleton.Item width="80%" height="12px" />
          <StyledLeafletSkeleton.Item width="80%" height="12px" />
          <StyledLeafletSkeleton.Item width="30%" height="12px" />
          <div style={{ marginBottom: 25 }} />
          <StyledLeafletSkeleton.Item width="80%" height="12px" />
          <StyledLeafletSkeleton.Item width="80%" height="12px" />
          <StyledLeafletSkeleton.Item width="30%" height="12px" />
        </StyledLeafletSkeleton.Title>
      </StyledLeafletSkeleton>
    </div>
  );
};

export default LeafletSkeleton;
