import React from 'react';
import { ChevronLeft, ChevronRight } from '@styled-icons/bootstrap';
import { StyledChapterShowcase } from '../../../organisms/ChapterShowcase/Mobile.styled';
import { Mobile as LeafletSkeleton } from '../../../molecules/Skeletons/LeafletSkeleton';
import { Mobile as TopNavbar } from '../../../molecules/TopNavbar/';
import { Mobile as MastHeadSkeleton } from '../../../molecules/Skeletons/MastHeadSkeleton';
import { StyledPagination } from '../../../molecules/Pagination/Mobile.styled';
import { Link } from 'react-router-dom';

const SkeletonLeaflet = () => {
  return (
    <div>
      <TopNavbar title="" backLink="/" />
      <StyledChapterShowcase>
        <MastHeadSkeleton/>
        <LeafletSkeleton />
      </StyledChapterShowcase>
      <StyledPagination>
        <Link to={'/'}>
          <ChevronLeft size={16} />
        </Link>
        <span className="active">2</span>
        <span>{' / '}</span>
        <span className="total">15</span>
        <Link to={'/'}>
          <ChevronRight size={16} />
        </Link>
      </StyledPagination>
    </div>
  );
};

export default SkeletonLeaflet;
