import styled from 'styled-components';
import { getColor } from '../../../utils/theme';

export const StyledMastHead = styled.section`
  display: flex;
  padding-top: 60px;
  padding-bottom: 20px;

  .player-wrapper {
    position: relative;
    height: 380px;
    border-radius: 8px;
    overflow: hidden;
  }
`;

StyledMastHead.Copy = styled.div`
  flex: 0 0 40%;
  padding-right: 48px;
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};

  .logo {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.header.text, theme.palette);
    return color;
  }};
    font-size: 20px;
    line-height: 27px;
    font-family: ProximaNovaBd;

    img {
      margin-right: 8px;
      width: 28px;
      height: 28px;
    }
  }

  h1,
  h2 {
    font-family: ProximaNovaExtraBd;
    line-height: 125%;
    margin: 0;

    * {
      font-family: inherit;
    }
  }

  h1 {
    font-size: 42px;
    line-height: 52.5px;
  }

  p {
    font-family: ProximaNovaRg;
    margin-top: 0;
    font-size: 16px;
    line-height: 24px;
    padding-top: 40px;
  }
`;

StyledMastHead.Illustration = styled.div`
  flex: 0 0 60%;
  overflow: hidden;
  border-radius: 8px;
  z-index: 1;

  img,
  .player-wrapper {
    width: 100%;
  }
`;
