import { atom, createStore } from 'jotai';

const store = createStore();

export const isGrantedAtom = atom(false);

isGrantedAtom.debugLabel = 'isGranted';

store.set(isGrantedAtom, false);

export default store;
