import styled from 'styled-components';
import { adjustOrder, getColor } from '../../../utils/theme';
import { DEVICE } from '../../../constants/layout';
import { Link } from 'react-router-dom';

export const StyledMainCard = styled(Link)`
  text-decoration: none;
  display: flex;
`;

StyledMainCard.Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.main.background, theme.palette);
    return color;
  }};
  border-radius: 6px;
  overflow: hidden;

  ${({ theme, isChapter }) => {
    if (isChapter) {
      const color = getColor(theme.scheme.main.focus, theme.palette);
      return `
        background: ${color};
        padding: 12px;
      `;
    }
  }}
`;

StyledMainCard.Teaser = styled.div`
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
`;

StyledMainCard.ModuleBadge = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.label, theme.palette);
    return color;
  }};
  background: ${({ theme, order }) => {
    const adjustedOrder = adjustOrder(order);
    const color = getColor(
      theme.scheme.module[adjustedOrder].caption,
      theme.palette,
    );
    return color;
  }};
  font-size: 12px;
  @media ${DEVICE.tablet} {
    font-size: 14px;
  }
  margin: 12px 0 0;
  font-family: ProximaNovaBd;
  border-radius: 4px;
  padding: 5px 8px;
  bottom: 0;
  left: 0;
`;

StyledMainCard.ChapterBadge = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 4px 0 0;
  font-size: 14px;
  @media ${DEVICE.tablet} {
    font-size: 14px;
  }
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.subtext, theme.palette);
    return color;
  }};
  background: transparent;
  bottom: 0;
  left: 0;

  .dot {
    display: block;
    margin-right: 5px;
    margin-left: 5px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: ${({ theme }) => {
    const color = getColor(theme.scheme.main.subtext, theme.palette);
    return color;
  }};
  }
`;

StyledMainCard.Content = styled.div`
  h2 {
    padding-top: 10px;
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};
    @media ${DEVICE.tablet} {
      font-size: 16px;
    }
  }

  p {
    padding-top: 2px;
    margin: 0;
    line-height: 20px;
    font-size: 14px;
    @media ${DEVICE.tablet} {
      font-size: 14px;
    }
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.subtext, theme.palette);
    return color;
  }};
  }

  p span {
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};
  }
`;
