import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';

export const StyledMastHeadSkeleton = styled.div``;

StyledMastHeadSkeleton.Content = styled.div`
  height: 210px;
  width: 100%;
  padding: 20px;
  padding-top: 40px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};
`;