export function trackGAEvent(category, action, label) {
  if (window.gtag && typeof window.gtag === 'function') {
    window.gtag('event', action, {
      'event_category': category,
      'event_label': label,
    });
  }
}

const googleAnalyticsEvents = {
  academyViewed: () => {
    trackGAEvent('default', 'Academy Viewed');
  },
  academyLoginSelected: (origin) => {
    trackGAEvent('Source', 'Academy Login Selected', origin);
  },
  academySignUpSelected: (origin) => {
    trackGAEvent('Source', 'Academy Sign Up Selected', origin);
  },
};

export default googleAnalyticsEvents;
