import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';

export const StyledLastReadCardSkeleton = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: start;
  flex-direction: row;
  position: relative;
  padding: 20px;
`;

StyledLastReadCardSkeleton.Card = styled.div`
  border-radius: 3px;
  width: 100%;
  height: 80px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};
`;