import React from 'react';
import { StyledTitleMainCardSkeleton } from './Desktop.styled';

const TitleMainCardSkeleton = () => {
  return (
    <StyledTitleMainCardSkeleton to="/">
      <StyledTitleMainCardSkeleton.Caption style={{ width: '144px' }}/>
    </StyledTitleMainCardSkeleton>
  );
};

export default TitleMainCardSkeleton;