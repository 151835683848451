import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';

export const StyledUnboxingSkeleton= styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => {
    return getColor(theme.scheme.main.background, theme.palette);
  }};
  padding: 20px;
  margin-bottom: 100px;
  padding-top: 44px;

  & > a {
    margin-top: 16px;
  }
`;

StyledUnboxingSkeleton.ChipWrapper = styled.div`
  height: 50px;
  display: flex;
  gap: 8px;
  padding: 11px 0;
`;

StyledUnboxingSkeleton.Chip = styled.div`
  height: 28px;
  border-radius: 2px;

  ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return `
      background: ${color};
    `;
  }}

  ${({ width }) => {
    if (width) {
      return `width: ${width};`;
    }
  }}
`;
