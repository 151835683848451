import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';

export const StyledHeadingSectionSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
`;

StyledHeadingSectionSkeleton.Video = styled.div`
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};

  height: 210px;
  width: 100%;

  ${({ isDesktop }) => {
    if (isDesktop) {
      return 'height: 325px;';
    }
  }}
  margin-bottom: 20px;
`;

StyledHeadingSectionSkeleton.Item = styled.span`
  height: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: block;

  ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return `
      background: ${color};
    `;
  }}

  ${({ width }) => {
    if (width) {
      return `width: ${width};`;
    }
  }}

  ${({ height }) => {
    if (height) {
      return `height: ${height};`;
    }
  }}

  ${({ borderRadius }) => {
    if (borderRadius) {
      return `border-radius: ${borderRadius};`;
    }
  }}

  ${({ marginBottom }) => {
    if (marginBottom) {
      return `margin-bottom: ${marginBottom};`;
    }
  }}

  ${({ margin }) => {
    if (margin) {
      return `margin: ${margin};`;
    }
  }}
`;
