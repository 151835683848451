import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';

export const StyledUnboxingEntrySkeleton = styled.div`
  margin-top: 40px;
  width: 100%;
  height: 396px;
  display: flex;
`;

StyledUnboxingEntrySkeleton.Banner = styled.div`
  border-radius: 8px;
  width: 704px;
  height: 100%;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);
    return color;
  }};
`;

StyledUnboxingEntrySkeleton.ListContainer = styled.div`
  border-radius: 8px;
  width: calc(100% - 704px);
  height: 100%;
  padding-left: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

StyledUnboxingEntrySkeleton.List = styled.div`
  height: 92px;
  display: flex;
`;

StyledUnboxingEntrySkeleton.ItemSquare = styled.div`
  border-radius: 8px;
  width: 92px;
  height: 92px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);
    return color;
  }};
`;

StyledUnboxingEntrySkeleton.TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
  flex: 1;
`;

StyledUnboxingEntrySkeleton.Title = styled(StyledUnboxingEntrySkeleton.ItemSquare)`
  border-radius: 4px;
  width: auto;
  max-width: 229px;
  height: 20px;
`;

StyledUnboxingEntrySkeleton.SubTitle = styled(StyledUnboxingEntrySkeleton.Title)`
  width: 114px;
  margin-top: 8px;
`;

StyledUnboxingEntrySkeleton.ListFooter = styled(StyledUnboxingEntrySkeleton.ItemSquare)`
  border-radius: 4px;
  width: auto;
  height: 44px;
`;