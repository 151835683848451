import React from 'react';

import { StyledUnboxingEntrySkeleton } from './Desktop.styled';

const UnboxingEntrySkeleton = () => {
  return (
    <StyledUnboxingEntrySkeleton>
      <StyledUnboxingEntrySkeleton.Banner />
      
      <StyledUnboxingEntrySkeleton.ListContainer>
        {[1, 2, 3].map((key) => (
          <StyledUnboxingEntrySkeleton.List key={key}>
            <StyledUnboxingEntrySkeleton.ItemSquare />
            <StyledUnboxingEntrySkeleton.TitleContainer>
              <StyledUnboxingEntrySkeleton.Title />
              <StyledUnboxingEntrySkeleton.SubTitle />
            </StyledUnboxingEntrySkeleton.TitleContainer>
          </StyledUnboxingEntrySkeleton.List>
        ))}
        <StyledUnboxingEntrySkeleton.ListFooter />
      </StyledUnboxingEntrySkeleton.ListContainer>
    </StyledUnboxingEntrySkeleton>
  );
};

export default UnboxingEntrySkeleton;