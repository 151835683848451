import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getColor } from '../../../utils/theme';
import { StyledQuizProgress as StyledDesktop } from './Desktop.styled';
import { DEVICE } from '../../../constants/layout';

export const StyledQuizProgress = styled.div``;
StyledQuizProgress.Heading = styled(StyledDesktop.Heading)`
  border: unset;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.subtext, theme.palette);
    return color;
  }};
  padding: 2px 20px;
  margin-bottom: 14px;
  width: 100%;
  flex: unset;
  @media ${DEVICE.tablet} {
    max-width: 548px;
    padding: 0;
    margin: auto;
    width: 100%;
  }
`;
StyledQuizProgress.Progress = styled(StyledDesktop.Progress)`
  margin-left: 40px;
`;
StyledQuizProgress.Content = styled(StyledDesktop.Content)`
  padding: 15px 20px;
  width: calc(100% - 16px);
`;
StyledQuizProgress.BackButton = styled(Link)`
  height: 44px;
  display: flex;
  align-items: center;

  svg {
    fill: ${({ theme }) => {
    const color = getColor(theme.scheme.main.caption, theme.palette);
    return color;
  }};
  }

  position: relative;
  transform: unset;
  left: unset;
  top: unset;
  margin-left: unset;
`;
