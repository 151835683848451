import styled from 'styled-components';
import { StyledMainShowcase } from '../../organisms/MainShowcase/Desktop.styled';

export const StyledReadingPlatform = styled(StyledMainShowcase)`
  padding-top: 30px;
`;

StyledReadingPlatform.Wrapper = styled(StyledMainShowcase.Wrapper)`
  display: flex;
  justify-content: flex-start;
`;

StyledReadingPlatform.LeafletWrapper = styled.div`
  flex: 0 0 calc(100% - 264px);
  width: calc(100% - 264px);
  padding-right: 48px;
`;

StyledReadingPlatform.LessonShowcaseWrapper = styled.div`
  flex: 0 0 264px;
  width: 264px;
  max-height: calc(100vh - 90px);
`;
