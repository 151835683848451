import React from 'react';
import { StyledTitleMainCardSkeleton } from './Mobile.styled';

const TitleMainCardSkeleton = () => {
  return (
    <StyledTitleMainCardSkeleton to="/">
      <StyledTitleMainCardSkeleton.Caption 
        style={{ 
          width: '48px', 
          height: '12px'
        }} 
      />
      <StyledTitleMainCardSkeleton.Caption 
        style={{ 
          width: '48px', 
          height: '12px'
        }} 
      />
    </StyledTitleMainCardSkeleton>
  );
};

export default TitleMainCardSkeleton;