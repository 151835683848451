import styled from 'styled-components';
import { getColor } from '../../../utils/theme';

export const StyledUnboxingDetailDescription = styled.aside`
  width: 100%;
  padding: 16px;
  min-height: 359px;
  border-radius: 6px;
  margin-bottom: 24px;
  border: ${({ theme }) => {
    const borderColor = getColor(theme.scheme.unboxing.card.border, theme.palette);
    return `1px solid ${borderColor}`;
  }};

  h2,
  h3 {
    margin: unset;
  }

  h2,
  p {
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};
  }

  h3 {
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.subtext, theme.palette);
    return color;
  }};
  }

  h2 {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 24px;
    font-family: ProximaNovaBd;
  }

  h3 {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    font-family: ProximaNovaRg;
  }

  p {
    font-size: 13px;
    line-height: 20px;
  }
`;