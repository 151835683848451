import styled from 'styled-components';
import { StyledMainCard } from '../../molecules/MainCard/Mobile.styled';
import { StyledMainShowcase } from '../MainShowcase/Mobile.styled';
import { getColor } from '../../../utils/theme';
import { StyledLeaflet } from '../Leaflet/Mobile.styled';
import { DEVICE } from '../../../constants/layout';

export const StyledChapterShowcase = styled(StyledMainShowcase)`
  position: relative;

  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

StyledChapterShowcase.PlayerWrapper = styled(StyledLeaflet.PlayerWrapper)``;

StyledChapterShowcase.Wrapper = styled(StyledMainShowcase.Wrapper)`
  margin: 20px auto;
  max-width: 648px;
  padding: 0 20px;
`;
StyledChapterShowcase.ListChapter = styled.div`
  margin: 0 auto;
  max-width: 648px;
  padding: 0;
  @media ${DEVICE.tablet} {
    padding: 0 20px;
  }
  a:first-of-type > div{
    border-top: 0;
  }
`;
StyledChapterShowcase.TitleWrapper = styled.div`
  ${({ theme }) => {
    const color = getColor(theme.scheme.main.border, theme.palette);
    return `border-bottom: 1px solid ${color};`;
  }}
`;
StyledChapterShowcase.Title = styled(StyledMainShowcase.Title)`
  margin: auto;
  max-width: 648px;
  border-bottom: 0;
`;
StyledChapterShowcase.Copy = styled(StyledMainCard.Content)`
  margin: auto;
  max-width: 648px;
  h2 {
    font-size: 20px;
    line-height: 28px;
    padding-top: 0;
    @media ${DEVICE.tablet} {
      font-size: 36px;
      line-height: 135%;
    }
  }

  p {
    font-size: 14px;
    line-height: 20px;
    padding-top: 10px;
    @media ${DEVICE.tablet} {
      font-size: 16px;
      line-height: 135%;
    }
  }
`;
