import React from 'react';
import { StyledBreadcrumbSkeleton } from './styled';

const BreadcrumbSkeleton = () => {
  return (
    <StyledBreadcrumbSkeleton to="/">
      <StyledBreadcrumbSkeleton.Caption style={{ width: '80px' }}/>
    </StyledBreadcrumbSkeleton>
  );
};

export default BreadcrumbSkeleton;