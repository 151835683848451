import React from 'react';
import { StyledUnboxingDetails } from '../../../templates/UnboxingDetails/Desktop.styled';
import { StyledUnboxingDetailShareAndDownload } from '../../../molecules/UnboxingDetailShareAndDownload/Desktop.styled';
import { StyledHeadingSectionSkeleton } from '../../../molecules/Skeletons/HeadingSectionSkeleton/styled';

const SkeletonUnboxingDetails = () => {
  const { Left, Right, Wrapper, Heading } = StyledUnboxingDetails;

  return (
    <StyledUnboxingDetails data-testid="unboxing-detail-skeleton">
      <Heading>
        <StyledHeadingSectionSkeleton.Item width="80px" height="20px" borderRadius="4px" marginBottom="10px" />
      </Heading>
      <Wrapper>
        <Left>
          <StyledUnboxingDetailShareAndDownload
            className="m-unboxingDetailDesc"
          >
            <StyledHeadingSectionSkeleton.Item width="80px" height="20px" borderRadius="4px" marginBottom="10px" />
            <StyledHeadingSectionSkeleton.Item width="114px" height="20px" borderRadius="4px" marginBottom="40px" />
            <StyledHeadingSectionSkeleton.Item width="183px" height="20px" borderRadius="4px" marginBottom="4px" />
            <StyledHeadingSectionSkeleton.Item width="183px" height="20px" borderRadius="4px" marginBottom="4px" />
            <StyledHeadingSectionSkeleton.Item width="114px" height="20px" borderRadius="4px" margin="0" />
          </StyledUnboxingDetailShareAndDownload>
          <StyledUnboxingDetailShareAndDownload
            className="m-unboxingDetailDesc"
            style={{
              marginTop: '24px'
            }}
          >
            <StyledHeadingSectionSkeleton.Item width="100%" height="20px" borderRadius="4px" marginBottom="10px" />
            <hr />
            <StyledHeadingSectionSkeleton.Item width="100%" height="20px" borderRadius="4px" marginBottom="10px" />
          </StyledUnboxingDetailShareAndDownload>
        </Left>
        <Right>
          <StyledHeadingSectionSkeleton.Item width="100%" height="100%" borderRadius="0" margin="0" />
        </Right>
      </Wrapper>
    </StyledUnboxingDetails>
  );
};

export default SkeletonUnboxingDetails;
