import React from 'react';
import { StyledLessonShowcaseSkeleton } from './Desktop.styled';
import { Desktop as LessonItemSkeleton } from '../LessonItemSkeleton';

const LessonShowcaseSkeleton = () => {
  return (
    <StyledLessonShowcaseSkeleton data-testid="lesson-showcase-skeleton">
      <StyledLessonShowcaseSkeleton.Title>
        <StyledLessonShowcaseSkeleton.Caption style={{ width: '80px', marginBottom: '10px' }}/>
        <StyledLessonShowcaseSkeleton.Caption style={{ width: '114px', marginBottom: '20px' }}/>
        <StyledLessonShowcaseSkeleton.Caption style={{ width: '183px', marginBottom: '4px' }}/>
        <StyledLessonShowcaseSkeleton.Caption style={{ width: '114px' }}/>
      </StyledLessonShowcaseSkeleton.Title>

      <StyledLessonShowcaseSkeleton.Content>
        {[1, 2, 3, 4, 5, 6, 7].map((key) => {
          return <LessonItemSkeleton key={key} />;
        })}
      </StyledLessonShowcaseSkeleton.Content>
    </StyledLessonShowcaseSkeleton>
  );
};

export default LessonShowcaseSkeleton;
