import React from 'react';
import PropTypes from 'prop-types';
import { Desktop as MastHeadSkeleton } from '../../../molecules/Skeletons/MastHeadSkeleton';
import { Desktop as MainCardSkeleton } from '../../../molecules/Skeletons/MainCardSkeleton';
import { Desktop as TitleMainCardSkeleton } from '../../../molecules/Skeletons/TitleMainCardSkeleton';
import { Desktop as LastReadCardSkeleton } from '../../../molecules/Skeletons/LastReadCardSkeleton';
import { Desktop as UnboxingEntrySkeleton } from '../../../molecules/Skeletons/UnboxingEntrySkeleton';
import { StyledMainShowcase } from '../../MainShowcase/Desktop.styled';
import TabCategorySkeleton from '../../../molecules/Skeletons/TabCategory';

const SkeletonMainShowcase = ({ isGranted }) => {
  return (
    <StyledMainShowcase>
      <MastHeadSkeleton />
      {isGranted ? (
        <StyledMainShowcase.Wrapper style={{
          display: 'flex',
          flexFlow: 'row',
          flexWrap: 'wrap',
        }}>
          <TitleMainCardSkeleton />
          {[1, 2, 3].map((key) => {
            return <LastReadCardSkeleton order={key} key={key} />;
          })}
        </StyledMainShowcase.Wrapper>
      ) : null}

      <TitleMainCardSkeleton />
      <TabCategorySkeleton />

      <StyledMainShowcase.Wrapper style={{
        display: 'flex',
        flexFlow: 'row',
        flexWrap: 'wrap',
      }}>
        {[1, 2, 3, 4].map((key) => {
          return <MainCardSkeleton order={key} key={key} />;
        })}
      </StyledMainShowcase.Wrapper>

      <TitleMainCardSkeleton />
      <StyledMainShowcase.Wrapper style={{
        display: 'flex',
        flexFlow: 'row',
        flexWrap: 'wrap',
      }}>
        <UnboxingEntrySkeleton />
      </StyledMainShowcase.Wrapper>

    </StyledMainShowcase>
  );
};

SkeletonMainShowcase.propTypes = {
  isGranted: PropTypes.bool.isRequired,
};

export default SkeletonMainShowcase;
