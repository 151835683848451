// A mapper to decide on where the source came from (similar to document.referrer on browser).
export const sourceMapper = {
  search: 'Search (Icon Academy)',
  sidemenu: 'Side Menu Bar',
  banner: 'Banner',
  deeplink: 'Deeplink',
  academy: 'Academy',
  news: 'Search',
  sharelink: 'Sharelink',
  research: 'Research Tab',
  default: null,
};

export type sourceMapper = typeof sourceMapper;

export type sourceMapperKeys = keyof typeof sourceMapper;

export const sourceMapperAcademy = {
  ...sourceMapper,
  research: 'Research Tab (Icon)'
};

export type sourceMapperAcademy = typeof sourceMapperAcademy;
