import styled from 'styled-components';
import { getColor } from '../../../utils/theme';
import { Link } from 'react-router-dom';
import { DEVICE } from '../../../constants/layout';

export const StyledLastRead = styled(Link)`
  text-decoration: none;
  display: flex;
  width: 100%;
  max-height: 134px;
  align-items: center;
  border-radius: 6px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.main.focus, theme.palette);
    return color;
  }};
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};
`;

StyledLastRead.TeaserWrapper = styled.div`
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex: 0 0 38%;
  @media ${DEVICE.tablet} {
    flex: 0 0 30%;
    max-width: 180px;
  }
  display: flex;
  position: relative;
`;

StyledLastRead.Teaser = styled.div`
  height: 60px;
  @media ${DEVICE.tablet} {
    height: 90px;
  }
  width: 100%;
  border-radius: 4px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.main.background, theme.palette);
    return color;
  }};
  background-image: ${({ image }) => {
    return `url("${image}")`;
  }};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

StyledLastRead.ContentWrapper = styled.div`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 58%;

  h2 {
    font-weight: 700;
    font-family: ProximaNovaBd;
    font-size: 14px;
    line-height: 20px;
    @media ${DEVICE.tablet} {
      font-size: 16px;
      line-height: 135%;
    }
    margin: 0;
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.caption, theme.palette);
    return color;
  }};
  }

  p {
    font-weight: normal;
    font-size: 12px;
    display: flex;
    margin: 0;
    align-items: center;
    line-height: 16px;
    font-family: ProximaNovaRg;
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.subtext, theme.palette);
    return color;
  }};

    @media ${DEVICE.tablet} {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .dot {
    display: block;
    margin-right: 5px;
    margin-left: 5px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: ${({ theme }) => {
    const color = getColor(theme.scheme.main.subtext, theme.palette);
    return color;
  }};
  }
`;


StyledLastRead.Wrapper = styled.div`
  margin: 20px;
`;

StyledLastRead.Title = styled.h2`
  font-size: 14px;
  @media ${DEVICE.tablet} {
    font-size: 16px;
  }
  height: 40px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  margin: 0;
  padding: 0 20px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.main.background, theme.palette);
    return color;
  }};
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};
  ${({ theme }) => {
    const color = getColor(theme.scheme.unboxing.border, theme.palette);
    return `border-bottom: 1px solid ${color};`;
  }}

  span {
    font-size: 14px;
    font-family: ProximaNovaBd;
    @media ${DEVICE.tablet} {
      font-size: 16px;
    }
  }

  a {
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.link, theme.palette);
    return color;
  }};

    span {
      font-size: 14px;
      font-family: ProximaNovaRg;
    }
    &:hover,
    &:active {
      color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.link, theme.palette);
    return color;
  }};
    }
  }
`;
