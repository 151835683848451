import React from 'react';
import { StyledUnboxingCard } from '../../UnboxingCard/Mobile.styled';
import { StyledHeadingSectionSkeleton } from '../HeadingSectionSkeleton/styled';

const UnboxingCardSkeleton = () => {
  const { Thumbnail, ContentWrapper } = StyledUnboxingCard;

  return (
    <StyledUnboxingCard to="">
      <Thumbnail />
      <ContentWrapper>
        <h2>
          <StyledHeadingSectionSkeleton.Item width="100%" height="12px" borderRadius="3px" />
        </h2>
        <span>
          <StyledHeadingSectionSkeleton.Item width="33.33%" height="12px" borderRadius="3px" />
        </span>
      </ContentWrapper>
    </StyledUnboxingCard>
  );
};

export default UnboxingCardSkeleton;
