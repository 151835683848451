import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BREAKPOINT } from '../../../constants/layout';
import useViewport from '@hooks/useViewport';
import {
  Desktop as SkeletonForDesktop,
  Mobile as SkeletonForMobile
} from '../../organisms/Skeletons/UnboxingDetails';

const Desktop = lazy(() =>
  import('./Desktop'),
);
const Mobile = lazy(() => import('./Mobile'));

function UnboxingDetails() {
  const { width, height } = useViewport();
  const [load, setLoad]  = useState(false);

  // It is important. Give 300ms for theme.json to load completely.
  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, 300);
  }, []);

  if (!load) {
    return null;
  }

  return (
    <Suspense fallback={width < BREAKPOINT ? <SkeletonForMobile /> : <SkeletonForDesktop />}>
      {width >= BREAKPOINT ? (
        <Desktop width={width} height={height} />
      ) : (
        <Mobile width={width} height={height} />
      )}
    </Suspense>
  );
}

export default UnboxingDetails;
