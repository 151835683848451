export const BREAKPOINT = 1025;

export const MAX_WIDTH_DESKTOP = 1120;

export const SIZE = {
  mobile: 727,
  tablet: 728,
};
export const DEVICE = {
  mobile: `(max-width: ${SIZE.mobile}px)`,
  tablet: `(min-width: ${SIZE.tablet}px)`,
};

export const DEFAULT_IMAGE_PLACEHOLDER = `${process.env.PUBLIC_URL}/img/greeting.jpg`;

export const DEFAULT_WORDING = 'Belajar investasi saham dari 0 dan GRATIS';

export const DEFAULT_VIDEO_TEASER =
  'https://www.youtube.com/watch?v=c4eaPZ-vjQM';

export const DEFAULT_VIDEO_TEASER_THUMBNAILL = 'https://img.youtube.com/vi/c4eaPZ-vjQM/hqdefault.jpg';
