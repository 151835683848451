import { adjustOrder, getColor } from '../../../utils/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledModuleCard = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  text-decoration: none;
  cursor: pointer;
  ${({ theme, order }) => {
    if (!order) {
      return null;
    }

    const adjustedOrder = adjustOrder(order);
    const color = getColor(theme.scheme.module[adjustedOrder].border, theme.palette);
    return `border: 1px solid ${color};`;
  }}
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  font-family: ProximaNovaBd;
  flex-basis: calc(50% - 30px);
  margin: 20px;
  margin-bottom: 0;

  &:nth-child(even) {
    margin-left: 0;
  }
`;

StyledModuleCard.Header = styled.div`
  width: 100%;
  height: 80px;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${({ image }) => {
    return `url("${image}")`;
  }};
`;

StyledModuleCard.Caption = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 45%;
  max-width: 80%;
  width: auto;
  border-radius: 0px 4px 0px 0px;
  height: 24px;
  display: flex;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  padding-left: 9px;
  background: ${({ theme, order }) => {
    const adjustedOrder = adjustOrder(order);
    const color = getColor(theme.scheme.module[adjustedOrder].caption, theme.palette);
    return color;
  }};
  font-family: ProximaNovaBd;
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.label, theme.palette);
    return color;
  }};
`;

StyledModuleCard.Content = styled.p`
  font-style: normal;
  font-family: ProximaNovaRg;
  font-weight: 600;
  width: 100%;
  font-size: 14px;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 10px 12px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.main.background, theme.palette);
    return color;
  }};
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};
`;
