import { forwardRef } from 'react';
import Img from 'react-cool-img';
import { CSSProperties } from 'styled-components';

export interface ImageProps {
  src: string; 
  alt?: string;
  height?: string | number;
  width?: string | number;
  style?: CSSProperties,
  className?: string;
  onClick?: () => void;
  placeholder?: string,
}

const Image = forwardRef(({
  src,
  alt,
  height,
  width,
  style,
  className,
  onClick,
  placeholder,
} : ImageProps, ref) => {
  return (
    <Img
      src={src}
      alt={alt}
      width={width}
      height={height}
      debounce={100}
      style={style}
      placeholder={placeholder}
      className={className}
      onClick={onClick}
      ref={ref}
    />
  );
});

Image.displayName = 'Image';

export default Image;
