import React from 'react';
import { StyledLessonItemSkeleton } from './Mobile.styled';

const LessonItemSkeleton = () => {
  return (
    <StyledLessonItemSkeleton to="/">
      <StyledLessonItemSkeleton.MainSection>
        <StyledLessonItemSkeleton.Label type="read" />
        <StyledLessonItemSkeleton.Heading type="read">
          <StyledLessonItemSkeleton.Item width="60%" height="12px" style={{ marginBottom: '4px' }} />
          <StyledLessonItemSkeleton.Item width="30%" height="12px" />
        </StyledLessonItemSkeleton.Heading>
      </StyledLessonItemSkeleton.MainSection>
    </StyledLessonItemSkeleton>
  );
};

export default LessonItemSkeleton;
