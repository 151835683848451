import React from 'react';
import { StyledUnboxingCard } from '../../UnboxingCard/Desktop.styled';
import { StyledHeadingSectionSkeleton } from '../HeadingSectionSkeleton/styled';

const UnboxingCardSkeleton = () => {
  const { Content } = StyledUnboxingCard;

  return (
    <StyledUnboxingCard>
      <StyledHeadingSectionSkeleton.Item width="100%" height="194px" borderRadius="6px" />
      <Content>
        <h2>
          <StyledHeadingSectionSkeleton.Item width="20%" height="16px" borderRadius="4px" />
        </h2>
        <h2>
          <StyledHeadingSectionSkeleton.Item width="70%" height="16px" borderRadius="4px" />
        </h2>
        <span>
          <StyledHeadingSectionSkeleton.Item width="33.33%" height="16px" borderRadius="4px" />
        </span>
      </Content>
    </StyledUnboxingCard>
  );
};

export default UnboxingCardSkeleton;
