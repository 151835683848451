import styled from 'styled-components';
import { getColor } from '../../../utils/theme';
import { DEVICE } from  '../../../constants/layout';
import { StyledLastRead } from '../LastRead/Mobile.styled';

export const StyledUnboxingCard = styled(StyledLastRead)`
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.main.background, theme.palette);
    return color;
  }};
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.unboxing.card.text, theme.palette);
    return color;
  }};
  border: ${({ theme }) => {
    const borderColor = getColor(theme.scheme.unboxing.card.border, theme.palette);
    return `1px solid ${borderColor}`;
  }};
  padding: 12px 16px;
  border-radius: 4px;

  svg {
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.unboxing.card.chevron, theme.palette);
    return color;
  }};
    width: 14px;

    @media ${DEVICE.tablet} {
      width: 20px;
    }
  }

  img {
    width: 60px;
    height: 60px;

    @media ${DEVICE.tablet} {
      width: 100px;
      height: 100px;
    }
  }
`;

StyledUnboxingCard.Thumbnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 4px;

  @media ${DEVICE.tablet} {
    width: 100px;
    height: 100px;
  }

  background: ${({ theme }) => {
    const color = getColor(theme.scheme.unboxing.card.border, theme.palette);
    return color;
  }};

  img {
    object-fit: cover;
    object-position: center center;
    border-radius: 4px;
  }
`;

StyledUnboxingCard.ContentWrapper = styled(StyledLastRead.ContentWrapper)`
  flex: 1;
  padding-left: 16px;
`;





