import React from 'react';
import { StyledChapterShowcase } from '../../ChapterShowcase/Desktop.styled';
import { Desktop as MastHeadSkeleton } from '../../../molecules/Skeletons/MastHeadSkeleton';
import { Desktop as TitleMainCardSkeleton } from '../../../molecules/Skeletons/TitleMainCardSkeleton';
import BreadcrumbSkeleton from '../../../molecules/Skeletons/BreadcrumbSkeleton';
import { Desktop as ChapterShowcaseSkeleton } from '../../../molecules/Skeletons/ChapterShowcaseSkeleton';

const SkeletonChapterShowcase = () => {
  return (
    <StyledChapterShowcase data-testid="chapter-showcase-skeleton-desktop">
      <StyledChapterShowcase.Wrapper>
        <BreadcrumbSkeleton/>
        <MastHeadSkeleton/>
        <TitleMainCardSkeleton/>
        {[1, 2, 3].map((key) => {
          return <ChapterShowcaseSkeleton order={key} key={key}/>;
        })}
      </StyledChapterShowcase.Wrapper>
    </StyledChapterShowcase>
  );
};

export default SkeletonChapterShowcase;
