import React from 'react';
import { StyledMastHeadSkeleton } from './Desktop.styled';

const MastHeadSkeleton = () => {
  return (
    <StyledMastHeadSkeleton>
      <StyledMastHeadSkeleton.Copy>
        <StyledMastHeadSkeleton.Item 
          style={{ 
            width: '144px', 
            marginBottom: '42px' 
          }}  
        />
        <StyledMastHeadSkeleton.Item 
          style={{ 
            width: '183px', 
            marginBottom: '40px' 
          }}  
        />
        <StyledMastHeadSkeleton.Item 
          style={{ 
            width: '368px', 
            marginBottom: '8px' 
          }}  
        />
        <StyledMastHeadSkeleton.Item 
          style={{ 
            width: '368px', 
            marginBottom: '8px' 
          }}  
        />
        <StyledMastHeadSkeleton.Item 
          style={{ 
            width: '183px', 
          }}  
        />
      </StyledMastHeadSkeleton.Copy>
      <StyledMastHeadSkeleton.Content/>
    </StyledMastHeadSkeleton>
  );
};

export default MastHeadSkeleton;