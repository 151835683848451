import React from 'react';
import { ChevronLeft } from '@styled-icons/bootstrap';
import { StyledQuizProgress } from '../../QuizProgress/Mobile.styled';
import { StyledQuestionSkeleton } from './Mobile.styled';

const QuestionShowcaseSkeleton = () => {
  return (
    <StyledQuestionSkeleton>
      <StyledQuestionSkeleton.Heading moduleId={'1'} style={{ marginBottom: '30px' }}>
        <StyledQuizProgress>
          <StyledQuizProgress.Content>
            <StyledQuizProgress.BackButton to={'/'}>
              <ChevronLeft size={16} />
            </StyledQuizProgress.BackButton>
            <StyledQuizProgress.Progress percentage={10} style={{ marginLeft: '40px' }}/>
            <div style={{ marginLeft: 40 }}>
              <span className="active">1</span>
              <span>/</span>
              <span>10</span>
            </div>
          </StyledQuizProgress.Content>
        </StyledQuizProgress>
      </StyledQuestionSkeleton.Heading>
      
      <StyledQuestionSkeleton.Content>
        <StyledQuestionSkeleton.Item width="55%" height="12px" />
        <StyledQuestionSkeleton.Item width="90%" height="20px" />
        <div style={{ margin: '60px 0' }}>
          <StyledQuestionSkeleton.Option>
            <StyledQuestionSkeleton.Item width="60%" height="12px"/>
          </StyledQuestionSkeleton.Option>
          <StyledQuestionSkeleton.Option>
            <StyledQuestionSkeleton.Item width="60%" height="12px"/>
          </StyledQuestionSkeleton.Option>
          <StyledQuestionSkeleton.Option>
            <StyledQuestionSkeleton.Item width="60%" height="12px"/>
          </StyledQuestionSkeleton.Option>
        </div>
      </StyledQuestionSkeleton.Content>
      <div style={{ dislay: 'flex', alignItems: 'center' }}>
        <StyledQuestionSkeleton.Item height="44px" 
          style={{
            width: '91%',
            position: 'fixed',
            bottom: 0,
            left: 16,
            marginBottom: 20,
          }}
        />
      </div>
    </StyledQuestionSkeleton>
  );
};

export default QuestionShowcaseSkeleton;
