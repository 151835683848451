import React from 'react';
import PropTypes from 'prop-types';
import { StyledChapterShowcase } from './Mobile.styled';

const {
  Content,
  Teaser,
  Caption,
  ChapterCardItem,
  Heading,
  TeaserWrapper,
  Text
} = StyledChapterShowcase;

const ChapterShowcaseSkeleton = ({ order }) => {
  return (
    <StyledChapterShowcase order={order} to="/">
      <ChapterCardItem>
        <Heading to="/">
          <TeaserWrapper>
            <Teaser />
          </TeaserWrapper>
          <Text>
            <Caption style={{ width: '68px', marginBottom: '4px' }} />
            <Caption style={{ width: '40px' }} />
          </Text>
        </Heading>

        <Content>
          <Caption style={{ width: '276px', marginBottom: '6px' }} />
          <Caption style={{ width: '276px', marginBottom: '6px' }} />
          <Caption style={{ width: '94px' }} />
        </Content>
      </ChapterCardItem>
    </StyledChapterShowcase>
  );
};

ChapterShowcaseSkeleton.propTypes = {
  order: PropTypes.number.isRequired,
};

export default ChapterShowcaseSkeleton;
