import styled from 'styled-components';
import { StyledLeaflet } from '../../../organisms/Leaflet/Mobile.styled';
import { StyledHeadingSectionSkeleton } from '../HeadingSectionSkeleton/styled';

export const StyledLeafletSkeleton = styled(StyledLeaflet)`
  padding: 0;
`;

StyledLeafletSkeleton.Title = styled(StyledLeaflet.Title)`
  border-bottom: 0;
  padding: 0;
`;

StyledLeafletSkeleton.Item = styled(StyledHeadingSectionSkeleton.Item)`
  border-radius: 3px;
`;