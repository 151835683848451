import React from 'react';

import { StyledTabCategory } from './styled';

export default function TabCategory() {
  const { TabContainer, Chip } = StyledTabCategory;
  return (
    <StyledTabCategory>
      <TabContainer>
        <Chip width="47px" />
        <Chip width="67px" />
        <Chip width="69px" />
      </TabContainer>
    </StyledTabCategory>
  );
}
