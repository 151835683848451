import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';
import { StyledQuestionShowcase } from '../../../organisms/QuestionShowcase/Desktop.styled';

export const StyledQuestionSkeleton = styled(StyledQuestionShowcase)`
  margin: 0;
  display: flex;
  flex-direction: column;
`;

StyledQuestionSkeleton.Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

StyledQuestionSkeleton.Option = styled.div`
  margin-bottom: 16px;
  padding: 16px 24px;
  width: 100%;
  height: 44px;
  border-radius: 6px;
  border: ${({ theme }) => {
    const color = getColor(theme.scheme.content.border, theme.palette);
    return `1px solid ${color};`;
  }};
`;


StyledQuestionSkeleton.Item = styled.div`
  border-radius: 3px;
  margin-bottom: 10px;

  ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return `
      background: ${color};
    `;
  }}

  ${({ width }) => {
    if (width) {
      return `width: ${width};`;
    }
  }}

  ${({ height }) => {
    if (height) {
      return `height: ${height};`;
    }
  }}
`;