import React from 'react';
import AuthWrapper from '../components/molecules/AuthWrapper';
import { setToLS } from '../utils/storage';

const Auth = () => {
  setToLS('HAS_GONE_THROUGH_AUTH', true);

  return <AuthWrapper renderProps={() => <div />} />;
};

export default Auth;
