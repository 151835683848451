import React, { lazy, Suspense, useEffect, useState } from 'react';
import NewsSkeleton from '../components/molecules/Skeletons/NewsSkeleton';
const NewsTemplate = lazy(() => import('../components/templates/News'));

const News = () => {
  const [load, setLoad]  = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, 300);
  }, []);

  if (!load) {
    return null;
  }

  return (
    <Suspense fallback={<NewsSkeleton />}>
      <NewsTemplate />
    </Suspense>
  );
};

export default News;
