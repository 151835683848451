import { StrictMode } from 'react';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'jotai';
import { Partytown } from '@builder.io/partytown/react';

import ThemeWrapper from '../../molecules/ThemeWrapper';
import ScrollToTop from '../../atoms/ScrollToTop';
import Routes from '../../organisms/Routes';
import store from '../../../store';
import useAppendGtagOnIframe from '@hooks/useAppendGtagOnIframe';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      staleTime: 1000 * 10 * 6, // 1 minute
    },
  },
});

export default function App() {
  const isLoadedViaIframe = useAppendGtagOnIframe();

  return (
    <StrictMode>
      {!isLoadedViaIframe ? (
        <Partytown
          debug={true}
          forward={['dataLayer.push']}
        />
      ) : null }
      <Provider store={store}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ScrollToTop />
            <ThemeWrapper>
              <Routes />
            </ThemeWrapper>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </BrowserRouter>
      </Provider>
    </StrictMode>
  );
}
