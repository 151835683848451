export interface Auth {
  academy_token: string;
  timestamp: string;
}

export enum AUTH_CONSTANT {
  // checkov:skip=CKV_SECRET_6 "It's just an enum. No token stored here."
  ACADEMY_TOKEN = 'academy_token',
  TIMESTAMP = 'timestamp',
  LAST_VISITED_PARAMS = 'LAST_VISITED_PARAMS',
  LAST_VISITED_PATHNAME = 'LAST_VISITED_PATHNAME'
}
