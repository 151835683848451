import React, { lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BREAKPOINT } from '../../../constants/layout';
import useViewport from '@hooks/useViewport';
import {
  Desktop as SkeletonForDesktop,
  Mobile as SkeletonForMobile
} from '../../organisms/Skeletons/UnboxingList';

export const Desktop = lazy(() => import('./Desktop'));
export const Mobile = lazy(() => import('./Mobile'));

const UnboxingList = ({ isGranted }) => {
  const { width } = useViewport();

  const [load, setLoad] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, 300);
  }, []);

  if (!load) {
    return null;
  }

  const isMobile = width < BREAKPOINT;
  const FallbackComponent = isMobile ? SkeletonForMobile : SkeletonForDesktop;
  const UnboxingComponent = isMobile ? Mobile : Desktop;

  return (
    <Suspense fallback={<FallbackComponent />}>
      <UnboxingComponent isGranted={isGranted} />
    </Suspense>
  );
};

UnboxingList.propTypes = {
  isGranted: PropTypes.bool.isRequired,
};

export default UnboxingList;
