import React from 'react';
import { StyledMastHeadSkeleton } from './Mobile.styled';

const MastHeadSkeleton = () => {
  return (
    <StyledMastHeadSkeleton to="/">
      <StyledMastHeadSkeleton.Content width="100%" height="210px" />
    </StyledMastHeadSkeleton>
  );
};

export default MastHeadSkeleton;