import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getColor } from '../../../utils/theme';

export const StyledTopNavbar = styled.header`
  display: block;
  position: fixed;
  width: 100%;
  z-index: 998;
`;

StyledTopNavbar.TitleSkeleton = styled.p`
  height: 20px;
  border-radius: 5px;
  margin-bottom: 10px;

  ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return `
      background: ${color};
    `;
  }}
`;

StyledTopNavbar.Content = styled.nav`
  display: flex;
  position: sticky;
  margin: unset;
  padding: unset;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 44px;
  font-family: 'ProximaNovaBd';
  font-weight: 700;
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.header.text, theme.palette);
    return color;
  }};
  background: ${({ theme, transparent }) => {
    if (transparent) {
      return 'transparent';
    }

    const color = getColor(theme.scheme.header.background, theme.palette);
    return color;
  }};
`;

StyledTopNavbar.BackButton = styled(Link)`
  height: 44px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  margin-left: 12px;
  align-items: center;

  svg {
    width: 30px;
    height: 20px;
    fill: ${({ theme }) => {
    const color = getColor(theme.scheme.main.subtext, theme.palette);
    return color;
  }};
  }
`;

StyledTopNavbar.CopyLink = styled.div`
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 100;
  position: absolute;
  right: 0;
`;
