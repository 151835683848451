import postMessage from './postMessage';
import { trackGAEvent } from './googleAnalytics';

export const EVENT_ACTION_TYPES = {
  MOUSE_CLICK: 'mouse_click',
  NAVIGATION: 'navigation',
};

/**
 * this function will do post message to parent window to send analytics events
 * @param {string} eventName name of the event
 * @param {ACTION_TYPES} action type of action to trigger analytic event
 * @param {any} data contains parameter to be recorded
 * @param {string} fn academyAnalyticsEvent | academyUserProfilingTracker
 * @return {void} no return after execute this function
 */
export function academyAnalyticsEvent(
  eventName: string | null = null,
  action: string | null = null,
  parameter: JSONObject = {},
  fn = 'academyAnalyticsEvent',
) {
  if (!eventName) {
    throw new Error('event name cannot be empty');
  }

  if (!window) {
    throw new Error('postmesage cannot invoked without browser window');
  }

  const message = JSON.stringify({
    id: 'academyAnalyticsEvent',
    fn,
    data: {
      event_name: eventName,
      action,
      parameter,
    },
  });
  
  postMessage(message);

  // only send academy analytics events, not academy user profiling
  if (eventName === 'Academy User Profiling') {
    return;
  }
  
  // send with google analytics
  if (parameter && Object.keys(parameter) && Object.keys(parameter).length >= 1) {
    Object.keys(parameter).forEach((key) => {
      trackGAEvent(key, eventName, parameter[key]);
    });
  } else {
    trackGAEvent('default', eventName);
  }
}

// User profiling events section
export const recordUserProfile = (
  theme = 'light',
  moduleName = '',
  chapterName = '',
  lessonName = '',
) => {
  academyAnalyticsEvent(
    'Academy User Profiling',
    EVENT_ACTION_TYPES.NAVIGATION,
    {
      'Academy Module Last Viewed': moduleName,
      'Academy Chapter Last View': chapterName,
      'Academy Lesson Last Watched': lessonName,
      'Light/Dark Mode': theme,
    },
    'academyUserProfilingTracker',
  );
};

// analytics events lists
export const onStockbitAcademyViewed = (source: string) => {
  academyAnalyticsEvent(
    'Academy Viewed',
    EVENT_ACTION_TYPES.NAVIGATION,
    {
      'Source': source
    }
  );
};

export const onAcademyModuleShared = () => {
  academyAnalyticsEvent(
    'Academy Module Share',
    EVENT_ACTION_TYPES.MOUSE_CLICK
  );
};

export const onAcademyModuleClicked = (moduleTitle: string, moduleId: number) => () => {
  academyAnalyticsEvent(
    'Academy Module Clicked',
    EVENT_ACTION_TYPES.MOUSE_CLICK,
    {
      'Module Name': moduleTitle,
      'Module ID': moduleId,
    },
  );
};

export const onAcademySeeAllModule = () => {
  academyAnalyticsEvent(
    'Academy See All Module',
    EVENT_ACTION_TYPES.MOUSE_CLICK
  );
};

export const onAcademyExploreModuleViewed = (source: string) => {
  academyAnalyticsEvent(
    'Academy Explore Module Viewed',
    EVENT_ACTION_TYPES.NAVIGATION,
    {
      'Source': source
    }
  );
};

export const onAcademyModuleViewed = (moduleTitle: string, moduleId: number, theme: string, source: string) => {
  academyAnalyticsEvent(
    'Academy Module Viewed',
    EVENT_ACTION_TYPES.NAVIGATION,
    {
      'Module Name': moduleTitle,
      'Module ID': moduleId,
      'Source': source
    },
  );
  recordUserProfile(theme, moduleTitle);
};

export const onAcademyChapterClicked = (
  moduleTitle: string,
  moduleId: number,
  chapterTitle: string,
  chapterId: number,
) => () => {
  academyAnalyticsEvent(
    'Academy Chapter Clicked',
    EVENT_ACTION_TYPES.MOUSE_CLICK,
    {
      'Module Name': moduleTitle,
      'Module ID': moduleId,
      'Chapter Name': chapterTitle,
      'Chapter ID': chapterId,
    },
  );
};

export const onAcademyChapterViewed = (
  moduleTitle: string,
  moduleId: number,
  chapterTitle: string,
  chapterId: number,
  theme: string,
  source: string,
) => {
  academyAnalyticsEvent(
    'Academy Chapter Viewed',
    EVENT_ACTION_TYPES.NAVIGATION,
    {
      'Module Name': moduleTitle,
      'Module ID': moduleId,
      'Chapter Name': chapterTitle,
      'Chapter ID': chapterId,
      'Source': source
    },
  );
  recordUserProfile(theme, moduleTitle, chapterTitle);
};

export const onAcademyLessonClicked = (
  moduleTitle: string,
  moduleId: number,
  chapterTitle: string,
  chapterId: number,
  lessonTitle: string,
  lessonId: number,
) => () => {
  academyAnalyticsEvent(
    'Academy Lesson Clicked',
    EVENT_ACTION_TYPES.MOUSE_CLICK,
    {
      'Module Name': moduleTitle,
      'Module ID': moduleId,
      'Chapter Name': chapterTitle,
      'Chapter ID': chapterId,
      'Lesson Name': lessonTitle,
      'Lesson ID': lessonId,
    },
  );
};

export const onAcademyLastWatchedClicked = (
  moduleTitle: string,
  moduleId: number,
  chapterTitle: string,
  chapterId: number,
  lessonTitle: string,
  lessonId: number,
) => () => {
  academyAnalyticsEvent(
    'Academy Lesson Clicked',
    EVENT_ACTION_TYPES.NAVIGATION,
    {
      'Module Name': moduleTitle,
      'Module ID': moduleId,
      'Chapter Name': chapterTitle,
      'Chapter ID': chapterId,
      'Lesson Name': lessonTitle,
      'Lesson ID': lessonId,
    },
  );
};

export const onAcademyLessonViewed = (
  moduleTitle: string,
  moduleId: number,
  chapterTitle: string,
  chapterId: number,
  lessonTitle: string,
  lessonId: number,
  theme: string,
) => {
  academyAnalyticsEvent(
    'Academy Lesson Viewed',
    EVENT_ACTION_TYPES.NAVIGATION,
    {
      'Module Name': moduleTitle,
      'Module ID': moduleId,
      'Chapter Name': chapterTitle,
      'Chapter ID': chapterId,
      'Lesson Name': lessonTitle,
      'Lesson ID': lessonId,
    },
  );
  recordUserProfile(theme, moduleTitle, chapterTitle, lessonTitle);
};

export const onAcademyLessonVideoInteract = (
  moduleTitle: string,
  moduleId: number,
  chapterTitle: string,
  chapterId: number,
  lessonTitle: string,
  lessonId: number,
  videoURL: string,
  videoState: string, // play | pause
) => {
  academyAnalyticsEvent(
    'Academy Lesson Video Interact',
    EVENT_ACTION_TYPES.MOUSE_CLICK,
    {
      'Module Name': moduleTitle,
      'Module ID': moduleId,
      'Chapter Name': chapterTitle,
      'Chapter ID': chapterId,
      'Lesson Name': lessonTitle,
      'Lesson ID': lessonId,
      'Video URL': videoURL,
      'Video State': videoState,
    },
  );
};

// When user click arrow > or < to navigating to each lesson
export const onAcademyLessonClickedInteractNavigation = (
  moduleTitle: string,
  moduleId: number,
  chapterTitle: string,
  chapterId: number,
  lessonTitle: string,
  lessonId: number,
) => (state: string) => {
  academyAnalyticsEvent(
    'Academy Lesson Clicked Interact Navigation',
    EVENT_ACTION_TYPES.MOUSE_CLICK,
    {
      'Module Name': moduleTitle,
      'Module ID': moduleId,
      'Chapter Name': chapterTitle,
      'Chapter ID': chapterId,
      'Lesson Name': lessonTitle,
      'Lesson ID': lessonId,
      State: state,
    },
  );
};

export const onAcademyLessonQuizViewed = (
  moduleTitle: string,
  moduleId: number,
  chapterTitle: string,
  chapterId: number,
  lessonTitle: string,
  lessonId: number,
) => {
  academyAnalyticsEvent(
    'Academy Lesson Quiz Viewed',
    EVENT_ACTION_TYPES.NAVIGATION,
    {
      'Module Name': moduleTitle,
      'Module ID': moduleId,
      'Chapter Name': chapterTitle,
      'Chapter ID': chapterId,
      'Lesson Name': lessonTitle,
      'Lesson ID': lessonId,
    },
  );
};

/**
 * Note for state:
 * 'Start Quiz' when user click on 'Ambil Quiz'
 * 'Next Chapter' when user click on 'Lanjut ke Chapter Selanjutnya'
 */
export const onAcademyLessonQuizStarted = (
  moduleTitle: string,
  moduleId: number,
  chapterTitle: string,
  chapterId: number,
  lessonTitle: string,
  lessonId: number,
  state: string, // 'Start Quiz' | 'Next Chapter'
) => {
  academyAnalyticsEvent(
    'Academy Lesson Quiz Started',
    EVENT_ACTION_TYPES.MOUSE_CLICK,
    {
      'Module Name': moduleTitle,
      'Module ID': moduleId,
      'Chapter Name': chapterTitle,
      'Chapter ID': chapterId,
      'Lesson Name': lessonTitle,
      'Lesson ID': lessonId,
      State: state,
    },
  );
};

/**
 * Note for userAnswerResult:
 * 'Correct' when user answer correctly
 * 'Wrong' when user answer wrong
 */
export const onAcademyLessonQuizSubmitted = (
  moduleTitle: string,
  moduleId: number,
  chapterTitle: string,
  chapterId: number,
  questionTitle: string,
  questionID: number,
  userAnswerResult: string, // 'Correct' | 'Wrong'
) => {
  academyAnalyticsEvent(
    'Academy Lesson Quiz Submitted',
    EVENT_ACTION_TYPES.MOUSE_CLICK,
    {
      'Module Name': moduleTitle,
      'Module ID': moduleId,
      'Chapter Name': chapterTitle,
      'Chapter ID': chapterId,
      'Question Title': questionTitle,
      'Question ID': questionID,
      'User Answer': userAnswerResult,
    },
  );
};


export const onAcadmyUnboxingArticleSelected = (source: string, volume: string, title: string) => {
  academyAnalyticsEvent(
    'Unboxing Article Selected',
    EVENT_ACTION_TYPES.MOUSE_CLICK,
    {
      'Source': source,
      'Volume': volume,
      'Volume Name': title,
    },
  );
};

export const onAcademyUnboxingViewMoreClicked = () => {
  academyAnalyticsEvent(
    'Unboxing More Sector Selected',
    EVENT_ACTION_TYPES.MOUSE_CLICK,
    {
      'Source': 'Academy',
    },
  );
};

export const onAcademyUnboxingListViewed = (source: string | null) => {
  academyAnalyticsEvent(
    'Unboxing Saham Viewed',
    EVENT_ACTION_TYPES.NAVIGATION,
    {
      'Source': source,
    },
  );
};

export const onAcademyUnboxingArticleViewed = (source: string, volume: string, title: string) => {
  academyAnalyticsEvent(
    'Unboxing Detail Article Viewed',
    EVENT_ACTION_TYPES.NAVIGATION,
    {
      'Source': source,
      'Volume': volume,
      'Volume Name': title,
    },
  );
};

export const onAcademyUnboxingDownloadClicked = (source: string, volume: string, title: string) => {
  academyAnalyticsEvent(
    'Unboxing Download Selected',
    EVENT_ACTION_TYPES.MOUSE_CLICK,
    {
      'Source': source,
      'Volume': volume,
      'Volume Name': title,
    },
  );
};

export const onAcademyUnboxingShareClicked = (volume: string, media: string, title: string) => {
  academyAnalyticsEvent(
    'Unboxing Share Selected',
    EVENT_ACTION_TYPES.MOUSE_CLICK,
    {
      'Volume': volume,
      'Media': media,
      'Volume Name': title,
    },
  );
};

export const onAcademyUnboxingPDFViewed = (volume: string, title: string) => {
  academyAnalyticsEvent(
    'Unboxing Article Viewed',
    EVENT_ACTION_TYPES.NAVIGATION,
    {
      'Volume': volume,
      'Volume Name': title,
    },
  );
};

export const onAcademyUnboxingReadArticleClicked = (volume: string, title: string) => {
  academyAnalyticsEvent(
    'Event Unboxing Read Article Selected',
    EVENT_ACTION_TYPES.MOUSE_CLICK,
    {
      'Volume': volume,
      'Volume Name': title,
    },
  );
};

export const onAcademyUnboxingPDFClosed = (volume: string, title: string) => {
  academyAnalyticsEvent(
    'Unboxing Article Closed',
    EVENT_ACTION_TYPES.NAVIGATION,
    {
      'Volume': volume,
      'Volume Name': title,
    },
  );
};

export const onAcademyRegisterViewed = () => {
  academyAnalyticsEvent(
    'Register Viewed',
    EVENT_ACTION_TYPES.NAVIGATION,
    {
      'Source': 'Academy',
    },
  );
};

export const onAcademyLoginViewed = () => {
  academyAnalyticsEvent(
    'Login Viewed',
    EVENT_ACTION_TYPES.NAVIGATION,
    {
      'Source': 'Academy',
    },
  );
};

export const onAcademyLogoutSelected = () => {
  academyAnalyticsEvent(
    'Academy Log Out Selected',
    EVENT_ACTION_TYPES.MOUSE_CLICK,
  );
};

export const onAcademyOpenApps = () => {
  academyAnalyticsEvent(
    'Open On Stockbit Apps',
    EVENT_ACTION_TYPES.NAVIGATION,
  );
};

export const onAcademyGuestModalViewed = (source: string) => {
  academyAnalyticsEvent(
    'Academy Login Modal Viewed',
    EVENT_ACTION_TYPES.MOUSE_CLICK,
    {
      'Source': source,
    },
  );
};

export const onAcademyGuestModalLogin = (source: string) => {
  academyAnalyticsEvent(
    'Academy Login Selected',
    EVENT_ACTION_TYPES.MOUSE_CLICK,
    {
      'Source': source,
    },
  );
};

export const onAcademyGuestModalSignup = (source: string) => {
  academyAnalyticsEvent(
    'Academy Sign Up Selected',
    EVENT_ACTION_TYPES.MOUSE_CLICK,
    {
      'Source': source,
    },
  );
};

export const onUnboxingFilterSelected = (filter: string) => {
  academyAnalyticsEvent(
    `Unboxing ${filter} Filter Selected`,
    EVENT_ACTION_TYPES.MOUSE_CLICK
  );
};
