import styled from 'styled-components';
import { getColor } from '../../../utils/theme';
import { StyledUnboxingDetailDescription } from '../UnboxingDetailDescription/styled';

export const StyledUnboxingDetailShareAndDownload = styled(StyledUnboxingDetailDescription)`
  min-height: unset;

  hr {
    border: unset;
    height: 0;
    padding: 0;
    border-top: ${({ theme }) => {
    const borderColor = getColor(theme.scheme.unboxing.card.border, theme.palette);
    return `1px solid ${borderColor}`;
  }};
    width: 100%;
    margin: 20px auto;
  }
`;

StyledUnboxingDetailShareAndDownload.Item = styled.div`
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};
  font-weight: bold;
  font-family: ProximaNovaBd;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  cursor: pointer;
  align-items: center;

  span {
    margin-left: 12px;
  }
`;
