import React from 'react';
import PropTypes from 'prop-types';
import { StyledChapterShowcase } from './Desktop.styled';

const { Content, Teaser, Caption, ChapterCardItem } = StyledChapterShowcase;

const ChapterShowcaseSkeleton = ({ order }) => {
  return (
    <StyledChapterShowcase order={order} to="/">
      <ChapterCardItem>
        <Teaser />
        <Content>
          <Caption style={{ width: '368px', marginBottom: '4px' }}/>
          <Caption style={{ width: '80px', marginBottom: '20px' }}/>
          <Caption style={{ width: '660px', marginBottom: '8px' }}/>
          <Caption style={{ width: '368px' }}/>
        </Content>
      </ChapterCardItem>
    </StyledChapterShowcase>
  );
};

ChapterShowcaseSkeleton.propTypes = {
  order: PropTypes.number.isRequired,
};

export default ChapterShowcaseSkeleton;
