import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getColor } from '../../../../utils/theme';

export const StyledChapterShowcase = styled(Link)`
  text-decoration: none;
  display: flex;
  height: auto;
  margin-top: 38px;
  padding: 0 30px;
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  width: 100%;
`;

StyledChapterShowcase.ChapterCardItem = styled.div`
  display: flex;
  padding: 30px 0;
`;

StyledChapterShowcase.Teaser = styled.div`
  flex: 0 0 33.33%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  max-width: 280px;
  width: 280px;
  height: 158px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);
    return color;
  }};

  img {
    width: 100%;
    height: 100%;
  }
`;

StyledChapterShowcase.Content = styled.div`
  flex: 0 0 62.28%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 30px;
`;


StyledChapterShowcase.Caption = styled.div`
  height: 20px;
  border-radius: 3px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);
    return color;
  }};
`;