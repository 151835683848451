import React, { useEffect } from 'react';
import { StyledLoading } from '../components/molecules/Loading/styled';
import { removeFromLS } from '../utils/storage';
import Image from '../components/atoms/Image';
import { CURRENT_LESSON_ID_AS_VISITOR, SHARED_LINK } from '../constants/lesson';

const Refresh = () => {
  useEffect(() => {
    removeFromLS('HAS_GONE_THROUGH_AUTH');
    removeFromLS('auth');
    removeFromLS(CURRENT_LESSON_ID_AS_VISITOR);
    removeFromLS(SHARED_LINK);
  }, []);

  return (
    <StyledLoading>
      <Image
        src={`${process.env.PUBLIC_URL}/img/stockbitsquare.svg`}
        alt="logo"
        width={120}
      />
      <h1>Refreshed</h1>
    </StyledLoading>
  );
};

export default Refresh;
