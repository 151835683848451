import styled from 'styled-components';
import { getColor } from '../../../utils/theme';

export const StyledQuizProgress = styled.div`
  display: flex;
  align-items: center;
  width: inherit;
  margin-bottom: 20px;
`;

StyledQuizProgress.Heading = styled.h3`
  font-size: 14px;
  line-height: 20px;
  font-family: ProximaNovaRg;
  flex: 0 0 calc(100% - 162px);
  font-weight: 400;
  margin: 0;
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.subtext, theme.palette);

    return color;
  }};

  .title {
    font-family: ProximaNovaBd;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};
  }

  .dot {
    display: inline-flex;
    height: 3px;
    width: 3px;
    margin: 2px 6px;
    border-radius: 50%;
    background: ${({ theme }) => {
    const color = getColor(theme.scheme.main.subtext, theme.palette);

    return color;
  }};
  }
`;

StyledQuizProgress.Content = styled.div`
  margin: 0;
  display: flex;
  padding: 20px;
  align-items: center;
  font-size: 14px;
  width: 162px;
  flex: 0 0 162px;
  line-height: 18px;
  text-align: left;
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.caption, theme.palette);

    return color;
  }};

  span {
    margin-right: 1px;
  }

  .active {
    font-weight: bold;
    font-family: ProximaNovaBd;
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);

    return color;
  }};
  }
`;

StyledQuizProgress.Progress = styled.span`
  display: flex;
  flex-wrap: nowrap;
  height: 4px;
  width: 100%;
  margin-left: 5px;
  border-radius: 8px;
  background: ${({ theme, percentage }) => {
    const strokeColor = getColor(
      theme.scheme.quiz.progress.stroke,
      theme.palette,
    );
    const backgroundColor = getColor(
      theme.scheme.quiz.progress.background,
      theme.palette,
    );

    return `linear-gradient(to right, ${strokeColor} 0%, ${strokeColor} ${percentage}%, ${backgroundColor} ${percentage}%, ${backgroundColor} 100%);`;
  }};
`;
