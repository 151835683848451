import React from 'react';
import { StyledLessonItemSkeleton } from './Desktop.styled';
import { StyledHeadingSectionSkeleton } from '../HeadingSectionSkeleton/styled';

const LessonItemSkeleton = () => {
  return (
    <StyledLessonItemSkeleton to="/">
      <StyledLessonItemSkeleton.MainSection>
        <StyledLessonItemSkeleton.Label type="read" />
        <StyledLessonItemSkeleton.Heading type="read">
          <StyledHeadingSectionSkeleton.Item width="60%" height="20px" style={{ marginBottom: '4px' }} />
          <StyledHeadingSectionSkeleton.Item width="40%" height="20px" />
        </StyledLessonItemSkeleton.Heading>
      </StyledLessonItemSkeleton.MainSection>
    </StyledLessonItemSkeleton>
  );
};

export default LessonItemSkeleton;
