import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getColor } from '../../../utils/theme';

export const StyledNews = styled.div`
  padding: 20px;
  background: ${({ theme }) => {
    if (!theme.scheme) {
      return '#ffffff';
    }

    const color = getColor(theme.scheme.main.background, theme.palette);
    return color;
  }};
  color: ${({ theme }) => {
    if (!theme.scheme) {
      return '#000000';
    }

    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};
`;

StyledNews.CTA = styled(Link)`
  color: ${({ theme }) => {
    if (!theme.scheme) {
      return '#000000';
    }

    const color = getColor(theme.scheme.main.link, theme.palette);
    return color;
  }};
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  text-decoration: none;
  align-items: center;
  display: flex;

  span {
    color: inherit;
  }
`;
