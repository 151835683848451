/**
 * this function will do post message to parent window to send analytics events
 * @param {string} message name of the event
 * @return {void} no return after execute this function
 */
export default function postMessage(message = '') {
  if (typeof message !== 'string') {
    throw new Error('Message should be string or stringified JSON');
  }

  if (!window) {
    throw new Error('postmesage cannot invoked without browser window');
  }

  // Android handler
  try {
    // -- check AcademyInjection vars
    const stringifiedVars = window?.AcademyInjection?.injectVariable();
    const injectedVars = JSON.parse(stringifiedVars);
    // check if contains isWebview
    const isWebview = injectedVars.isWebview;
    // post message via AcademyInjection
    if (isWebview) {
      window?.AcademyInjection?.sendFromWeb(message);
      return;
    }
  } catch (error) {
    // iOS handler
    // -- check webkit message handlers
    if (window?.webkit?.messageHandlers) {
      window?.webkit?.messageHandlers?.stockbitiOSListener?.postMessage(
        message,
      );
      return;
    }
  }

  // Web / iFrame Handler
  const isLoadedViaIframe = window.location !== window.parent.location;

  // Only post event if loaded via iframe
  if (isLoadedViaIframe) {
    window.parent.postMessage(message, process.env.REACT_APP_STOCKBIT_BASE_URL);
  }
}

/**
 * this function will do post message to parent window to send analytics events
 * @param {object} history react router dom history hooks
 * @param {object} event custom event
 * @return {void} no return after execute this function
 */
export function handleAndroidDeviceBack(history, backlink) {
  if (!history) {
    throw new Error('Cannot read history');
  }

  return (event) => {
    try {
      if (!event) {
        throw new Error(
          'postmesage cannot invoked without window event listener',
        );
      }

      // validate event detail
      if (!event.detail) {
        throw new Error('cannot read event detail');
      }
      
      console.log('event:', event.detail);

      // parse event detail

      const payload = JSON.parse(event.detail);
      console.log('payload:', payload);
      if (payload.id && payload.id !== 'goBack') {
        return;
      }
      if (backlink === '/exit') {
        history.replace('/');
      } else {
        history.replace(backlink);
      }
      console.log('Backlink:', backlink);
    } catch (error) {
      if (error instanceof error) {
        console.error(error.message);
      }
      // do nothing
      return;
    }
  };
}
