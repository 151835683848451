import React from 'react';
import { StyledTitleChapterSkeleton } from './styled';

const TitleChapterSkeleton = () => {
  return (
    <StyledTitleChapterSkeleton>
      <StyledTitleChapterSkeleton.Caption 
        style={{ 
          width: '172px', 
          height: '20px',
          marginBottom: '25px'
        }} 
      />
      <StyledTitleChapterSkeleton.Caption 
        style={{ 
          width: '276px', 
          height: '12px',
          marginBottom: '6px'
        }} 
      />
      <StyledTitleChapterSkeleton.Caption 
        style={{ 
          width: '276px', 
          height: '12px',
          marginBottom: '6px'
        }} 
      />
      <StyledTitleChapterSkeleton.Caption 
        style={{ 
          width: '94px', 
          height: '12px',
        }} 
      />
    </StyledTitleChapterSkeleton>
  );
};

export default TitleChapterSkeleton;