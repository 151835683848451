/**
 * Store value to localStorage
 * @param {string} key
 * @param {string} value
 */
export const setToLS = (key: string, value: JSONValue) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Store value to sessionStorage
 * @param {string} key
 * @param {string} value
 */
export const setToSessionStorage = (key: string, value: string) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

/**
 * Retrieve value from localStorage
 * @param {string} key
 * @returns {JSONValue | undefined}
 */
export const getFromLS = (key: string): JSONValue | undefined => {
  const value = window.localStorage.getItem(key);

  if (value) {
    const parsedObj: JSONValue = JSON.parse(value);
    return parsedObj;
  }

  return undefined;
};

/**
 * Retrieve value from sessionStorage
 * @param {string} key
 * @returns {JSONValue | undefined}
 */
export const getFromSessionStorage = (key: string): JSONValue | undefined => {
  const value = window.sessionStorage.getItem(key);

  if (value) {
    const parsedObj: JSONValue = JSON.parse(value);
    return parsedObj;
  }

  return undefined;
};

/**
 * Remove value from localStorage
 * @param {string} key
 */
export const removeFromLS = (key: string) => {
  window.localStorage.removeItem(key);
};

/**
 * Remove value from sessionStorage
 * @param {string} key
 */
export const removeFromSessionStorage = (key: string) => {
  window.sessionStorage.removeItem(key);
};
