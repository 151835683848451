import styled from 'styled-components';
import { getColor } from '../../../utils/theme';

export const StyledQuestionShowcase = styled.div`
  margin-top: 60px;
  margin-bottom: 90px;
  width: 100%;
`;

StyledQuestionShowcase.Content = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;

StyledQuestionShowcase.Heading = styled.div`
  max-width: 500px;
  padding: 0;
  margin: 0 auto;
  width: 100%;
`;
StyledQuestionShowcase.Title = styled.div`
  border-bottom: unset;
  padding: 0;
  font-size: 28px;
  margin-bottom: 48px;
  line-height: 34px;
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};
  font-family: ProximaNovaBd;

  * {
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};
    font-size: 28px;
    line-height: 34px;
    font-family: ProximaNovaBd;
  }

  img {
    width: 100%;
    cursor: zoom-in;
  }
`;

StyledQuestionShowcase.MultipleChoice = styled.div`
  display: flex;
  flex-direction: column;
`;
StyledQuestionShowcase.Item = styled.button`
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 16px 20px;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  text-align: left;
  display: flex;
  justify-content: space-between;
  ${({ theme, type }) => {
    let border = '';
    let text = '';
    let background = '';
    let scheme = {};

    switch (type) {
    case 'correct':
      scheme = theme.scheme.button.success;
      break;
    case 'wrong':
      scheme = theme.scheme.button.danger;
      break;
    case 'active':
      scheme = theme.scheme.button.active;
      break;
    default:
      scheme = theme.scheme.button.default;
      break;
    }
    border = getColor(scheme.border, theme.palette);
    text = getColor(scheme.text, theme.palette);
    background = getColor(scheme.background, theme.palette);
    const fontWeight = type === 'wrong' || type === 'correct' ? 'bold' : 'normal';
    const fontFamily = type === 'wrong' || type === 'correct' ? 'ProximaNovaBd' : 'ProximaNovaRg';

    return `
      border: 1px solid ${border};
      color: ${text};
      background: ${background};
      font-weight: ${fontWeight};
      font-family: ${fontFamily};
    `;
  }};
`;
