import React from 'react';
import AuthWrapper from '../components/molecules/AuthWrapper';
import UnboxingListTemplate from '../components/templates/UnboxingList';

const UnboxingList = () => {
  return (
    <AuthWrapper renderProps={UnboxingListTemplate} />
  );
};

export default UnboxingList;