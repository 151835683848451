import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';

export const StyledSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: ${({ theme }) => {
    const color = getColor(
      theme.scheme.header.background, 
      theme.palette
    );
    return color;
  }};
`;

StyledSkeletonWrapper.NavigationBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 18px 23px;

  svg {
    width: 30px;
    height: 20px;
    fill: ${({ theme }) => {
    const color = getColor(theme.scheme.main.subtext, theme.palette);
    return color;
  }};
  }
`;

StyledSkeletonWrapper.HeaderBox = styled.div`
  height: 300px;
  width: 100%;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);
    return color;
  }};
`;

StyledSkeletonWrapper.DescriptionBox = styled.div`
  padding: 20px;
`;

StyledSkeletonWrapper.TextWrap = styled.div`
  max-width: 82%;
  height: 93px;
`;


StyledSkeletonWrapper.Title = styled.div`
  height: 20px;
  width: 62%;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);
    return color;
  }};
  border-radius: 4px;
  margin-bottom: 25px;
`;

StyledSkeletonWrapper.Desc = styled(StyledSkeletonWrapper.Title)`
  width: 100%;
  height: 12px;
  margin-bottom: 6px;

  &:last-child {
    width: 32%;
    margin-bottom: 0;
  }
`;

