import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';

export const StyledLessonShowcaseSkeleton = styled.div``;

StyledLessonShowcaseSkeleton.Content = styled.div`
`;

StyledLessonShowcaseSkeleton.Title = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: ${({ theme }) => {
    const color = getColor(theme.scheme.content.border, theme.palette);
    return `1px solid ${color};`;
  }};
`;

StyledLessonShowcaseSkeleton.Caption = styled.div`
  height: 20px;
  border-radius: 3px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);
    return color;
  }};
`;