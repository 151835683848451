import React, { lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';
import useViewport from '@hooks/useViewport';
import { BREAKPOINT } from '../../../constants/layout';
import SkeletonLeaflet from '../../organisms/Skeletons/Leaflet';

const Mobile = lazy(() => import('./Mobile'));

const Lesson = ({ isGranted }) => {
  const { moduleId, chapterId, lessonId } = useParams();
  const [load, setLoad]  = useState(false);
  const { width } = useViewport();

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, 300);
  }, []);

  // Redirect to reading platform on desktop if viewport touches BREAKPOINT.
  if (width >= BREAKPOINT) {
    return (
      <Redirect
        to={`/modules/${moduleId}/chapters/${chapterId}/lessons?lessonId=${lessonId}`}
      />
    );
  }

  if (!load) {
    return null;
  }

  return (
    <Suspense fallback={<SkeletonLeaflet />}>
      <Mobile isGranted={isGranted} />
    </Suspense>
  );
};

Lesson.propTypes = {
  isGranted: PropTypes.bool.isRequired,
};

export default Lesson;
