import React from 'react';
import PropTypes from 'prop-types';
import { StyledLastReadCardSkeleton } from './Desktop.styled';

const LastReadCardSkeleton = ({ order }) => {
  return (
    <StyledLastReadCardSkeleton order={order} to="/"> 
      <StyledLastReadCardSkeleton.Card />
    </StyledLastReadCardSkeleton>
  );
};

LastReadCardSkeleton.propTypes = {
  order: PropTypes.number.isRequired,
};

export default LastReadCardSkeleton;