import styled from 'styled-components';
import { getColor, getUnboxingCategoryColor } from '../../../../utils/theme';
import { Link } from 'react-router-dom';

export const StyledImageCard = styled(Link)`
  position: relative;
  cursor: pointer;
`;

StyledImageCard.Ilustration = styled.div`
  height: 396px;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  position: relative;
  border-radius: 6px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);
    return color;
  }};

  ::before {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => {
    const color = getColor(theme.scheme.unboxing.card.overlay, theme.palette);
    return color;
  }};
    content: '';
    border-radius: 6px;
  }

  .banner-img {
    border-radius: 6px;
    object-fit: cover;
  }
`;

StyledImageCard.Category = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  height: 24px;
  width: 56px;
  padding: 2px 6px;
  border-radius: 4px;
  background: ${({ theme, category }) => {
    const categoryColor = getUnboxingCategoryColor(category);
    const color = getColor(theme.scheme.unboxing.category[categoryColor], theme.palette);
    return color;
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  span {
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    min-width: 39px;
    font-family: ProximaNovaBd;
  }
`;

StyledImageCard.Information = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px;
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.duration.text, theme.palette);
    return color;
  }};
`;

StyledImageCard.Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  padding-top: 7px;
  width: 60px;
  font-weight: bold;
  margin-bottom: 20px;
  border-radius: 15px;
  font-size: 12px;
  line-height: 16px;
  font-family: ProximaNovaBd;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  border: ${({ theme }) => {
    const color = getColor(theme.scheme.progress.stroke, theme.palette);
    return `1px solid ${color}`;
  }};
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.progress.stroke, theme.palette);
    return color;
  }};
`;

StyledImageCard.Title = styled.h2`
  font-size: 24px;
  line-height: initial;
  font-family: ProximaNovaBd;
  margin: 0;
  margin-bottom: 8px;
  width: 368px;

  span {
    display: inline;
    background: ${({ theme }) => {
    const color = getColor(theme.scheme.unboxing.title.background, theme.palette);
    return color;
  }};
    padding: 2px 4px 0px;
    letter-spacing: normal;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    line-height: inherit;
    font-family: ProximaNovaBd;
  }
`;

StyledImageCard.SubTitle = styled(StyledImageCard.Title)`
  font-family: ProximaNovaRg;
  font-weight: normal;
  font-size: 14px;
  line-height: initial;
  margin: 0;

  span {
    padding: 8px 4px 4px;
    font-family: ProximaNovaRg;
  }
`;
