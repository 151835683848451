import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';

export const StyledLastReadCardSkeleton = styled.div`
  box-sizing: border-box;
  flex-direction: column;
  margin-bottom: 72px;
  width: calc(33.33% - 36px);
  margin-left: 36px;
  margin-top: 48px;
`;

StyledLastReadCardSkeleton.Card = styled.div`
  border-radius: 8px;
  width: 100%;
  height: 192px;
  margin-left: -36px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};
`;