import styled from 'styled-components';
//import { BREAKPOINT, MAX_WIDTH_DESKTOP } from '../../../../constants/layout';
import { getColor } from '../../../../utils/theme';

export const StyledMainCardSkeleton = styled.div`
  box-sizing: border-box;
  flex-direction: column;
  margin-bottom: 72px;
  width: calc(25% - 36px);
  margin-left: 36px;
  margin-top: 48px;
`;

StyledMainCardSkeleton.ModuleCard = styled.div`
  width: 100%;
  margin-left: -36px;
`;

StyledMainCardSkeleton.Image = styled.div`
  width: 100%;
  height: 156px;
  border-radius: 3px;
  margin-bottom: 16px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};
`;

StyledMainCardSkeleton.Caption = styled.div`
  height: 20px;
  border-radius: 3px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};
`;