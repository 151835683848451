import styled from 'styled-components';
import { SIZE } from '../../../constants/layout';
import { getColor } from '../../../utils/theme';
import { StyledChapterShowcase } from '../ChapterShowcase/Mobile.styled';
import { StyledModuleShowcase } from '../ModuleShowcase/Mobile.styled';

const { Title } = StyledModuleShowcase;

export const StyledLessonShowcase = styled.section`
  padding-top: 44px;
  margin-bottom: 120px;

  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  ${({ width }) => {
    if (width > SIZE.mobile) {
      return `
        .sticky-outer-wrapper.active {
          height: 200px !important;
          .sticky-inner-wrapper {
            padding-bottom: 0;
            & > div {
              width: 200px !important;
              height: 110px !important;
            }

            width: 200px !important;
            height: 110px !important;
            position: fixed;
            top: unset !important;
            bottom: 20px !important;
            right: 20px !important;
            transform: translate3d(0px, 0px, 0px) !important;
            z-index: 2;
          }
        }
      `;
    }
  }}
`;

StyledLessonShowcase.Wrapper = styled(StyledChapterShowcase.Wrapper)``;
StyledLessonShowcase.Copy = styled(StyledChapterShowcase.Copy)``;
StyledLessonShowcase.TitleWrapper = styled.div`
  ${({ theme }) => {
    const color = getColor(theme.scheme.main.border, theme.palette);
    return `border-bottom: 1px solid ${color};`;
  }}
`;
StyledLessonShowcase.Title = styled(Title)`
  margin: auto;
  max-width: 648px;
  border-bottom: 0;
  font-size: 14px;
  line-height: 135%;
`;
StyledLessonShowcase.Content = styled.div`
  padding: 16px;
  padding-bottom: 0;
  width: 100%;
  margin: 20px auto;
  max-width: 648px;
`;
StyledLessonShowcase.CTAWrapper = styled.div`
  display: flex;
  padding: 16px 0;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px;
  border-top: ${({ theme }) => {
    const color = getColor(theme.scheme.main.border, theme.palette);
    return `1px solid ${color};`;
  }};
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.main.background, theme.palette);
    return color;
  }};
`;
StyledLessonShowcase.CTAWrapper.CTA = styled.div`
  height: 36px;
  width: 100%;
  padding: 16px;
`;
