import styled from 'styled-components';
import { StyledMainShowcase } from '../MainShowcase/Desktop.styled';
import { getColor } from '../../../utils/theme';

export const StyledUnboxingShowcase = styled(StyledMainShowcase)``;

StyledUnboxingShowcase.Wrapper = styled(StyledMainShowcase.Wrapper)``;

StyledUnboxingShowcase.TabContainer = styled.div`
  display: flex;
  margin: 40px auto;
  height: 56px;
  padding: 0 20px;
  gap: 16px;
  border-bottom: ${({ theme }) => {
    const color = getColor(theme.scheme.unboxing.card.border, theme.palette);
    return `1px solid ${color}`;
  }};
`;

StyledUnboxingShowcase.Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0px 12px;
  height: 102%;
  font-weight: ${({ active }) => {
    const fontWeight = active ? 700 : 400;
    return fontWeight;
  }};
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: ${({ theme, active }) => {
    const selectColor = active ? 'active' : 'text';
    const color = getColor(theme.scheme.unboxing.tab[selectColor], theme.palette);
    return color;
  }};
  border-bottom: ${({ theme, active }) => {
    if (active) {
      const color = getColor(theme.scheme.unboxing.tab.active, theme.palette);
      return `1px solid ${color}`;
    }
  }};
`;

StyledUnboxingShowcase.UnboxingItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  article {
    width: calc((100% - 80px) / 3);
    margin-left: 40px;
  }

  article:nth-child(3n + 1) {
    margin-left: 0;
  }

  article:nth-child(n + 4) {
    margin-top: 60px;
  }

  article span {
    width: 100%;
    display: flex;
  }

  article > a > div span {
    width: unset;
  }

  & > span {
    width: 100%;
    display: flex;
    margin-top: 20px;
  }
`;
