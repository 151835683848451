import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';
//import { BREAKPOINT, MAX_WIDTH_DESKTOP } from '../../../../constants/layout';

export const StyledBreadcrumbSkeleton = styled.div`
  padding: 30px 0;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

StyledBreadcrumbSkeleton.Caption = styled.div`
  height: 20px;
  border-radius: 3px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};
`;