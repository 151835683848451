import styled from 'styled-components';
import { BREAKPOINT, MAX_WIDTH_DESKTOP } from '../../../constants/layout';
import { getColor } from '../../../utils/theme';

export const StyledMainShowcase = styled.main`
  width: 100%;
  padding: 0 12px;
  margin-bottom: 90px;
`;

StyledMainShowcase.Wrapper = styled.div`
  max-width: ${MAX_WIDTH_DESKTOP}px;
  min-width: ${BREAKPOINT}px;
  margin: 0 auto;
`;

StyledMainShowcase.Title = styled.h2`
  font-size: 20px;
  margin: 0;
  line-height: 28px;
  font-family: ProximaNovaBd;
  color: ${({ theme }) => {
    const color = getColor(theme.scheme.main.text, theme.palette);
    return color;
  }};
  margin-top: 52px;
  margin-bottom: 20px;
`;

StyledMainShowcase.LastWatchTitle = styled(StyledMainShowcase.Title)`
  margin-top: 100px;
`;

StyledMainShowcase.Modules = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -48px;
  margin-left: -36px;

  article {
    box-sizing: border-box;
    margin-bottom: 20px;
    width: calc(25% - 36px);
    margin-left: 36px;
    margin-top: 48px;
  }
`;

StyledMainShowcase.LastWatch = styled(StyledMainShowcase.Modules)`
  article {
    width: calc(33.33% - 48px);
  }
`;

StyledMainShowcase.Unboxing = styled.div`
  margin-top: 52px;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

StyledMainShowcase.UnboxingHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.progress.stroke, theme.palette);
    return color;
  }};
  }
`;

StyledMainShowcase.UnboxingTitle = styled(StyledMainShowcase.Title)`
  margin: 0;
  display: flex;
  align-items: center;
`;

StyledMainShowcase.TabContainer = styled.div`
  display: flex;
  margin: 20px auto 40px;
  height: 56px;
  padding: 0 20px;
  gap: 16px;
  border-bottom: ${({ theme }) => {
    const color = getColor(theme.scheme.unboxing.card.border, theme.palette);
    return `1px solid ${color}`;
  }};
`;

StyledMainShowcase.Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0px 12px;
  height: 102%;
  font-weight: ${({ active }) => {
    const fontWeight = active ? 700 : 400;
    return fontWeight;
  }};
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: ${({ theme, active }) => {
    const selectColor = active ? 'active' : 'text';
    const color = getColor(theme.scheme.unboxing.tab[selectColor], theme.palette);
    return color;
  }};
  border-bottom: ${({ theme, active }) => {
    if (active) {
      const color = getColor(theme.scheme.unboxing.tab.active, theme.palette);
      return `1px solid ${color}`;
    }
  }};
`;
