import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';
import { StyledLastRead } from '../../LastRead/Mobile.styled';
import { DEVICE } from '../../../../constants/layout';
import { StyledMainCard } from '../../MainCard/Mobile.styled';

export const StyledChapterShowcase = styled.div`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  overflow: hidden;
  padding: 0 20px;
  @media ${DEVICE.tablet} {
    padding: 0;
  }
`;

StyledChapterShowcase.ChapterCardItem = styled.div`
  padding: 10px 0;
  @media ${DEVICE.tablet} {
    padding: 10px;
  }
`;

StyledChapterShowcase.Heading = styled(StyledLastRead)`
  width: 100%;
  height: 100%;
  background: transparent;
  @media ${DEVICE.tablet} {
    margin: 10px 0;
  }
`;

StyledChapterShowcase.TeaserWrapper = styled(StyledLastRead.TeaserWrapper)`
  flex: 0 50%;
  min-width: 150px;
  max-width: 160px;
  padding: 10px 12px 12px 0;
  @media ${DEVICE.tablet} {
    padding: 20px;
    min-width: 200px;
    max-width: 240px;
  }
`;

StyledChapterShowcase.Teaser = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 56.2%;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);
    return color;
  }};

  img {
    width: 100%;
    height: 100%;
  }
`;

StyledChapterShowcase.Text = styled(StyledLastRead.ContentWrapper)`
  flex: 0 50%;
  @media ${DEVICE.tablet} {
    flex: 0 65%;
  }
`;


StyledChapterShowcase.Content = styled(StyledMainCard.Content)`
  padding: 0 0 10px 0;
  padding-top: 0;
`;


StyledChapterShowcase.Caption = styled.div`
  height: 12px;
  border-radius: 3px;
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);
    return color;
  }};
`;
