import React from 'react';

import UnboxingDetailSkeleton from '../../../molecules/Skeletons/UnboxingDetailSkeleton';

const SkeletonUnboxingDetails = () => {
  return (
    <div>
      <UnboxingDetailSkeleton />
    </div>
  );
};


export default SkeletonUnboxingDetails;
