import { useEffect } from 'react';
import { AUTH_CONSTANT } from '../constants/auth';
import { getFromLS, removeFromLS, setToLS } from '../utils/storage';
import { useStore } from 'jotai';
import { isGrantedAtom } from '../store';
import { History, Location } from 'history';
import { isAuth } from '@utils/auth';

const useAuth = (location: Location, history: History) => {
  const store = useStore();
  const isGranted = store.get(isGrantedAtom);

  // Component did mount
  useEffect(() => {
    const { search, pathname } = location;
    const queryParams = new URLSearchParams(search);
    const token = queryParams.get('token');
    const source = queryParams.get('source');
    const redirecTo = queryParams.get('redirect_to')
      ? queryParams.get('redirect_to')
      : '/';
    const timestamp = Number(queryParams.get('timestamp')); // UNIX timestamp
    const theme = queryParams.get('theme');
    const auth = getFromLS('auth');
    const sourceQS = source ? `?source=${source}` : '';

    // If token stored in localStorage, assume users are eligible to consume locked content.
    // Server will decide whether or not user is eligible later on.
    if (auth && isAuth(auth) && auth[AUTH_CONSTANT.ACADEMY_TOKEN]) {
      store.set(isGrantedAtom, true);
    }

    if (!auth && token) {
      setToLS('auth', {
        academy_token: token,
        timestamp: timestamp || Math.floor(Date.now() / 1000),
      });
      setToLS('HAS_GONE_THROUGH_AUTH', true);
    }

    // Excludes /unboxing and /auth, so it won't be remebered when visited.
    if (pathname !== '/auth' && !pathname.startsWith('/unboxing')) {
      setToLS(AUTH_CONSTANT.LAST_VISITED_PATHNAME, pathname);
      setToLS(AUTH_CONSTANT.LAST_VISITED_PARAMS, search);
    }

    const lastVisitedPathnameFromLS = getFromLS(
      AUTH_CONSTANT.LAST_VISITED_PATHNAME,
    );
    const lastVisitedParamsFromLS = new URLSearchParams(
      getFromLS(AUTH_CONSTANT.LAST_VISITED_PARAMS) as string,
    );

    // Append or set theme if not defined yet
    lastVisitedParamsFromLS.set('theme', theme as string);

    const lastVisitedFromLS =
      lastVisitedPathnameFromLS === '/' || !lastVisitedPathnameFromLS
        ? `/${sourceQS}`
        : `${lastVisitedPathnameFromLS}?${lastVisitedParamsFromLS.toString()}`;
    const redirectUrl = `${redirecTo}${sourceQS}` || lastVisitedFromLS;

    if (pathname === '/auth') {
      removeFromLS('auth');
      setToLS('auth', {
        academy_token: token,
        timestamp: timestamp || Math.floor(Date.now() / 1000),
      });

      // Authenticated or not, user will always be redirected to home page or specified page.
      if (redirectUrl) {
        history.push(decodeURIComponent(redirectUrl));
      } else {
        history.push(`/${sourceQS}`);
      }

      return;
    }
  }, [location.pathname]);

  return isGranted;
};

export default useAuth;
