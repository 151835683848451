import React, { lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BREAKPOINT } from '../../../constants/layout';
import useViewport from '@hooks/useViewport';
import { Desktop as QuestionDesktopSkeleton } from '../../molecules/Skeletons/QuestionShowcaseSkeleton';
import { Mobile as QuestionMobileSkeleton } from '../../molecules/Skeletons/QuestionShowcaseSkeleton';

const Desktop = lazy(() => import('./Desktop'));
const Mobile = lazy(() => import('./Mobile'));

const Quiz = ({ isGranted }) => {
  const { width } = useViewport();
  const [load, setLoad]  = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, 300);
  }, []);

  if (!load) {
    return null;
  }

  return (
    <Suspense fallback={width < BREAKPOINT ? <QuestionMobileSkeleton /> : <QuestionDesktopSkeleton />}>
      {width < BREAKPOINT ? (
        <Mobile isGranted={isGranted} />
      ) : (
        <Desktop isGranted={isGranted} />
      )}
    </Suspense>
  );
};

Quiz.propTypes = {
  isGranted: PropTypes.bool.isRequired,
};

export default Quiz;
