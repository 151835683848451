import styled from 'styled-components';
import { getColor } from '../../../../utils/theme';
import { StyledModuleCard } from '../../ModuleCard/styled';
import { StyledHeadingSectionSkeleton } from '../HeadingSectionSkeleton/styled';

export const StyledModuleCardSkeleton = styled(StyledModuleCard)`
  border: 0;
  flex-basis: calc(50% - 10px);
  margin: 0;
  margin-bottom: 30px;
`;

StyledModuleCardSkeleton.Header = styled.div`
  background: ${({ theme }) => {
    const color = getColor(theme.scheme.skeleton.background, theme.palette);

    return color;
  }};
  width: 100%;
  height: 80px;
`;

StyledModuleCardSkeleton.Content = styled(StyledModuleCard.Content)``;

StyledModuleCardSkeleton.Item = styled(StyledHeadingSectionSkeleton.Item)`
  border-radius: 3px;
`;
