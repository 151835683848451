import React from 'react';
import PropTypes from 'prop-types';
import { Mobile as MastHeadSkeleton } from '../../../molecules/Skeletons/MastHeadSkeleton';
import { Mobile as MainCardSkeleton } from '../../../molecules/Skeletons/MainCardSkeleton';
import { Mobile as TitleMainCardSkeleton } from '../../../molecules/Skeletons/TitleMainCardSkeleton';
import { Mobile as LastReadCardSkeleton } from '../../../molecules/Skeletons/LastReadCardSkeleton';
import { Mobile as UnboxingEntrySkeleton } from '../../../molecules/Skeletons/UnboxingEntrySkeleton';
import { StyledModuleShowcase } from '../../ModuleShowcase/Mobile.styled';

const SkeletonMainShowcase = ({ isGranted }) => {
  return (
    <StyledModuleShowcase unscrollable>
      <MastHeadSkeleton />
      <span className='space' style={{ marginBottom: '60px' }} />
      {isGranted ? (
        <>
          <TitleMainCardSkeleton />
          <LastReadCardSkeleton />
        </>
      ) : null}
      <TitleMainCardSkeleton />
      <>
        {[1, 2, 3].map((key) => {
          return <MainCardSkeleton order={key} key={key} />;
        })}
      </>

      <TitleMainCardSkeleton />
      <UnboxingEntrySkeleton />
    </StyledModuleShowcase>
  );
};

SkeletonMainShowcase.propTypes = {
  isGranted: PropTypes.bool.isRequired,
};

export default SkeletonMainShowcase;