import React from 'react';
import { ChevronLeft } from '@styled-icons/bootstrap';

import { StyledSkeletonWrapper } from './styled';

const UnboxingDetailSkeleton = () => {
  const {
    NavigationBar,
    HeaderBox,
    DescriptionBox,
    TextWrap,
    Title,
    Desc
  } = StyledSkeletonWrapper;

  return (
    <StyledSkeletonWrapper>
      <NavigationBar>
        <ChevronLeft size={14} />
      </NavigationBar>

      <HeaderBox />

      <DescriptionBox>
        <TextWrap>
          <Title />
          <Desc />
          <Desc />
          <Desc />
        </TextWrap>
      </DescriptionBox>
    </StyledSkeletonWrapper>
  );
};

export default UnboxingDetailSkeleton;