import styled from 'styled-components';
import { getColor } from '../../../utils/theme';
import { DEVICE, SIZE } from '../../../constants/layout';

export const StyledLeaflet = styled.section`
  padding-top: 44px;

  ${({ width }) => {
    if (width > SIZE.mobile) {
      return `
        .sticky-outer-wrapper.active {
          height: 150px !important;
          .sticky-inner-wrapper {
            & > div {
              width: 200px !important;
              height: 110px !important;
            }

            width: 200px !important;
            height: 110px !important;
            position: fixed;
            top: unset !important;
            bottom: 68px !important;
            right: 20px !important;
            transform: translate3d(0px, 0px, 0px) !important;
            z-index: 2;
          }
        }
      `;
    }
  }}
`;

StyledLeaflet.Wrapper = styled.article`
  padding: 0 20px;
  margin-bottom: 44px;

  @media ${DEVICE.tablet} {
    max-width: 648px;
    margin: 0 auto 90px;
  }

  div {
    font-size: 17px;
    line-height: 28px;
    @media ${DEVICE.tablet} {
      font-size: 19px;
      line-height: 31px;
    }
  }

  *:not(a) {
    font-family: sans-serif;
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.content.desc, theme.palette);
    return color;
  }};
  }

  .contentMarkdown {
    img {
      width: 100%;
    }

    padding-bottom: 60px;
  }
`;

StyledLeaflet.PlayerWrapper = styled.div`
  width: 100%;
  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    &::after { content: ""; background: transparent; width: 40%; height: 70%; min-height: 1px; position: absolute; top: 0; left: 0 }
    &::before { content: ""; background: transparent; width: 40%; height: 70%; min-height: 1px; position: absolute; top: 0; right: 0 }
  }
`;

StyledLeaflet.Title = styled.section`
  padding: 20px 0;
  margin-bottom: 20px;
  @media ${DEVICE.tablet} {
    padding: 30px 0;
    margin-bottom: 30px;
  }
  border-bottom: ${({ theme }) => {
    const color = getColor(theme.scheme.content.border, theme.palette);
    return `1px solid ${color};`;
  }};
  .title {
    font-family: ProximaNovaBd;
    font-size: 24px;
    line-height: 32px;
    @media ${DEVICE.tablet} {
      font-size: 36px;
      line-height: 48px;
    }
    margin: 0;
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.content.title, theme.palette);
    return color;
  }};
  }

  .time {
    font-family: ProximaNovaRg;
    font-size: 14px;
    @media ${DEVICE.tablet} {
      font-size: 16px;
    }
    line-height: 21px;
    color: ${({ theme }) => {
    const color = getColor(theme.scheme.content.subtext, theme.palette);
    return color;
  }};
  }
`;
