import React from 'react';
import ModuleCardSkeleton from '../../../molecules/Skeletons/ModuleCardSkeleton';
import { StyledModuleShowcase } from '../../ModuleShowcase/Mobile.styled';

const SkeletonModuleShowcase = () => {
  const { CardsWrapper, ChipWrapper, Chip } = StyledModuleShowcase;

  return (
    <StyledModuleShowcase data-testid="module-showcase-skeleton">
      <ChipWrapper>
        <Chip width="47px" />
        <Chip width="67px" />
        <Chip width="69px" />
      </ChipWrapper>

      <CardsWrapper isSkeleton>
        {[1, 2, 3, 4, 5, 6].map((key) => {
          return <ModuleCardSkeleton order={key} key={key} />;
        })}
      </CardsWrapper>
    </StyledModuleShowcase>
  );
};

export default SkeletonModuleShowcase;
