import { createGlobalStyle } from 'styled-components';
import { getColor } from '../../../utils/theme';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: ProximaNovaRg;
    outline: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ProximaNovaBd;
  }

  button {
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
  }

  a {
    text-decoration: none;
  }

  button {
    outline: none;
  }

  body.lb-open-lightbox {
    overflow: hidden;
  }

  .m-unboxingDetailDesc {
    overflow: unset;
  }

  .o-pdfPreview {
    scrollbar-width: thin;
  }

  body,
  .popup-content,
  .o-lessonShowcaseWrapper,
  .react-pdf__Document,
  .o-pdfPreview,
  .m-pdfAside,
  .virtual-list-pdf,
  .m-unboxingDetailDesc,
  .lessonShowcase {
    background: ${({ theme }) => {
    const color = getColor(theme.scheme.main.background, theme.palette);
    return color;
  }};

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-button:start:decrement,
    &::-webkit-scrollbar-button:end:increment {
      background-color: transparent;
      display: block;
      height: 0;
    }

    &::-webkit-scrollbar-track {
      background-clip: padding-box;
      border: solid 
        transparent;
      border-width: 0 0 0 4px;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }

    &::-webkit-scrollbar:hover {
      background-color: #f3f3f3;
      border-left: 1px solid #dbdbdb;
    }

    &::-webkit-scrollbar-thumb:vertical,
    &::-webkit-scrollbar-thumb:horizontal {
      background-color: #c6c6c6;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border: solid transparent;
      border-width: 1px 1px 1px 2px;
      -webkit-box-shadow: inset 1px 1px 0 rgba(0,0,0,0.10),inset 0 -1px 0 rgba(0,0,0,0.07);
      -moz-box-shadow: inset 1px 1px 0 rgba(0,0,0,0.10),inset 0 -1px 0 rgba(0,0,0,0.07);
      box-shadow: inset 1px 1px 0 rgba(0,0,0,0.10),inset 0 -1px 0 rgba(0,0,0,0.07);
      background-clip: padding-box;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #949494;
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.35);
      -moz-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.35);
      box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.35);
    }
  }

  .lessonShowcase__content {
    &::-webkit-scrollbar {
      background-color: transparent;
      height: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &:hover {
      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
        background-color: #f3f3f3;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border: solid transparent;
        border-width: 1px 1px 1px 2px;
        -webkit-box-shadow: inset 1px 1px 0 rgba(0,0,0,0.10),inset 0 -1px 0 rgba(0,0,0,0.07);
        -moz-box-shadow: inset 1px 1px 0 rgba(0,0,0,0.10),inset 0 -1px 0 rgba(0,0,0,0.07);
        box-shadow: inset 1px 1px 0 rgba(0,0,0,0.10),inset 0 -1px 0 rgba(0,0,0,0.07);
        background-clip: padding-box;
      }
    }
  }

  .m-pdfAside {
    background: ${({ theme }) => {
    const color = getColor(
      theme.scheme.pdf.preview.indicator.background,
      theme.palette,
    );
    return color;
  }}
`;
